import { default as _91provider_93vSUJOaRoSqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/[provider].vue?macro=true";
import { default as unverified4QADYNzRMqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/unverified.vue?macro=true";
import { default as verificationHTV4AiWEDyMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/verification.vue?macro=true";
import { default as coinsXhF1f5wr8nMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/coins.vue?macro=true";
import { default as my_45roomspUzyiqro7pMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/my-rooms.vue?macro=true";
import { default as notificationsLHnRcwGUYRMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/notifications.vue?macro=true";
import { default as planOIvwmqXHGMMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/plan.vue?macro=true";
import { default as profileqbpJGJAHtBMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/profile.vue?macro=true";
import { default as createu4QXFkRMd8Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/create.vue?macro=true";
import { default as indexVBpJ0dhaABMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/index.vue?macro=true";
import { default as adminseEgrtqcIvKMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins.vue?macro=true";
import { default as chatvJOa61A8puMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/chat.vue?macro=true";
import { default as editrk4IYsyFU8Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/edit.vue?macro=true";
import { default as indexcAPEZWHqLVMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/index.vue?macro=true";
import { default as settingsANDLKXfSqdMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/settings.vue?macro=true";
import { default as _91roomId_938ndOxeYoD0Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId].vue?macro=true";
import { default as creates0jBvZbuE9Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/create.vue?macro=true";
import { default as indexq2njqYw1BrMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/index.vue?macro=true";
import { default as general9NzBfJL6ocMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/general.vue?macro=true";
import { default as passwordr2ofh2UMvoMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/password.vue?macro=true";
import { default as privacy_45policyjSKVPtZDjlMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/privacy-policy.vue?macro=true";
import { default as refund_45policyoezZjs3rnvMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/refund-policy.vue?macro=true";
import { default as social_45profilescSJ8ZXgt07Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/social-profiles.vue?macro=true";
import { default as supportsoNwM1gymUMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/support.vue?macro=true";
import { default as terms4OrBsL18y0Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/terms.vue?macro=true";
import { default as settingsuIDrsjF5ceMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings.vue?macro=true";
import { default as setup_45profilefhLYTKPNI1Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/setup-profile.vue?macro=true";
import { default as indexynZGdFwRSIMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/subscriptions/index.vue?macro=true";
import { default as dashboard0mhrbND48jMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard.vue?macro=true";
import { default as forgot_45passwordTW2ztJsKMcMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/forgot-password.vue?macro=true";
import { default as indexjPZKzqX8tZMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/index.vue?macro=true";
import { default as logindnL4NFUXNoMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/login.vue?macro=true";
import { default as offline1u9GVFEPbgMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/offline.vue?macro=true";
import { default as privacy_45policyiTQhGGMy3xMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/privacy-policy.vue?macro=true";
import { default as _91username_93ateA8wEOjqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/profile/[username].vue?macro=true";
import { default as refund_45policyLLBhcHDm3ZMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/refund-policy.vue?macro=true";
import { default as registrationi1S1WKOSmuMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/registration.vue?macro=true";
import { default as reset_45passwordDv32R8YhuNMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/reset-password.vue?macro=true";
import { default as support5hdgcy95iEMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/support.vue?macro=true";
import { default as termsPZu7C32YQeMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/terms.vue?macro=true";
import { default as uiTvmYs8qqIeMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/ui.vue?macro=true";
import { default as component_45stubERhWKQNoqDMeta } from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubERhWKQNoqD } from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-provider___en",
    path: "/auth/:provider()",
    meta: _91provider_93vSUJOaRoSqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/[provider].vue").then(m => m.default || m)
  },
  {
    name: "auth-unverified___en",
    path: "/auth/unverified",
    meta: unverified4QADYNzRMqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/unverified.vue").then(m => m.default || m)
  },
  {
    name: "auth-verification___en",
    path: "/auth/verification",
    meta: verificationHTV4AiWEDyMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/verification.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: dashboard0mhrbND48jMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-coins___en",
    path: "coins",
    meta: coinsXhF1f5wr8nMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/coins.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-rooms___en",
    path: "my-rooms",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/my-rooms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plan___en",
    path: "plan",
    meta: planOIvwmqXHGMMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/plan.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: profileqbpJGJAHtBMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: _91roomId_938ndOxeYoD0Meta?.name,
    path: "rooms/:roomId()",
    meta: _91roomId_938ndOxeYoD0Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId].vue").then(m => m.default || m),
    children: [
  {
    name: adminseEgrtqcIvKMeta?.name,
    path: "admins",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rooms-roomId-admins-create___en",
    path: "create",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rooms-roomId-admins___en",
    path: "",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rooms-roomId-chat___en",
    path: "chat",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/chat.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rooms-roomId-edit___en",
    path: "edit",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rooms-roomId___en",
    path: "",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rooms-roomId-settings___en",
    path: "settings",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rooms-create___en",
    path: "rooms/create",
    meta: creates0jBvZbuE9Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rooms___en",
    path: "rooms",
    meta: indexq2njqYw1BrMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings___en",
    path: "settings",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-settings-general___en",
    path: "general",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-password___en",
    path: "password",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-privacy-policy___en",
    path: "privacy-policy",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-refund-policy___en",
    path: "refund-policy",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-social-profiles___en",
    path: "social-profiles",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/social-profiles.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-support___en",
    path: "support",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/support.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-terms___en",
    path: "terms",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-setup-profile___en",
    path: "setup-profile",
    meta: setup_45profilefhLYTKPNI1Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/setup-profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subscriptions___en",
    path: "subscriptions",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/subscriptions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45passwordTW2ztJsKMcMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexjPZKzqX8tZMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: logindnL4NFUXNoMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "offline___en",
    path: "/offline",
    meta: offline1u9GVFEPbgMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/offline.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    meta: privacy_45policyiTQhGGMy3xMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username___en",
    path: "/profile/:username()",
    meta: _91username_93ateA8wEOjqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___en",
    path: "/refund-policy",
    meta: refund_45policyLLBhcHDm3ZMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "registration___en",
    path: "/registration",
    meta: registrationi1S1WKOSmuMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    meta: reset_45passwordDv32R8YhuNMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/support",
    meta: support5hdgcy95iEMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsPZu7C32YQeMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "ui___en",
    path: "/ui",
    meta: uiTvmYs8qqIeMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/ui.vue").then(m => m.default || m)
  },
  {
    name: component_45stubERhWKQNoqDMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubERhWKQNoqD
  }
]