const useRoomsStore = defineStore('rooms', () => {
  const apiRoutes = useApiRoutes();
  const myRoomsStore = useMyRoomStore();
  const rooms = ref<(RoomPreview | Room)[]>([]);
  const meta = ref<PaginationMetaCursor>({});
  const handleRequest = useHandleRequest();
  let abortController: AbortController;
  const loading = ref(false);
  const fetchRooms = async (query: Keyable = {}, backgroundLoaded = false) => {
    if (!backgroundLoaded) {
      loading.value = true;
    }

    abortController?.abort();
    abortController = new AbortController();
    const res = await handleRequest(
      apiRoutes.rooms.index<ResWithCursorPagination<RoomPreview[]>>(
        {
          ...query
        },
        {
          timeout: 0,
          signal: abortController.signal
        }
      )
    );
    if (!res.isError) {
      rooms.value = res.data.data;
      meta.value = res.data.meta;

      if (!myRoomsStore.loadings[myRoomsStore.activeType]) {
        myRoomsStore.rooms[myRoomsStore.activeType] = updateItemsArray(
          myRoomsStore.rooms[myRoomsStore.activeType],
          rooms.value
        );
      }
    }

    loading.value = false;
    return res;
  };

  const onUpdateRoom = (room: Partial<RoomPreview>) => {
    rooms.value = updateItemArray<RoomPreview, 'id'>(rooms.value, room);
  };

  const setRooms = (newRooms: (RoomPreview | Room)[]) => {
    rooms.value = newRooms;
  };

  const reset = () => {
    rooms.value = [];
    meta.value = {};
    loading.value = false;
  };

  return {
    rooms,
    meta,
    loading,

    reset,
    fetchRooms,
    onUpdateRoom,
    setRooms
  };
});

export default useRoomsStore;
