const Gender = {
  Male: 1,
  Female: 2,
  Other: 3
} as const;

type TGender = keyof typeof Gender;
type TTGender = (typeof Gender)[keyof typeof Gender];

type TGenderSelect = {
  [key in TTGender]: {
    label: string;
    value: TTGender;
  };
};

const GenderSelect: TGenderSelect = {
  [Gender.Female]: {
    label: 'Female',
    value: Gender.Female
  },
  [Gender.Male]: {
    label: 'Male',
    value: Gender.Male
  },
  [Gender.Other]: {
    label: 'Other',
    value: Gender.Other
  }
};

export { Gender, GenderSelect, type TGender, type TGenderSelect, type TTGender };
