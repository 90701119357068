<template>
  <div class="flex flex-col justify-center items-center text-center lg:gap-7 gap-2.5">
    <ui-typography
      :text="message"
      variant="h3"
      class="max-w-2xl mx-auto text-balance"
    />
    <ui-typography variant="h3">
      <i18n-t
        keypath="If you can’t find the link, please check the spam folder."
        scope="global"
      />
    </ui-typography>
  </div>
</template>

<script setup lang="ts">
  const message = MessagesSuccess.forgotPassword;
</script>
