<template>
  <div class="typography">
    <div class="flex flex-wrap justify-between gap-4 items-center">
      <h1 class="">Terms of Use Agreement</h1>
      <p>Last updated July 29, 2024</p>
    </div>
    <p>
      <u>
        By clicking &laquo;Sign Up&raquo; on the registration forms, You indicate Your acceptance of all the terms and
        conditions of this Agreement and your registration on SDSV&amp;Meeting.
      </u>
    </p>
    <h2>TABLE OF CONTENTS:</h2>
    <ol>
      <li>
        <a href="#terms-eligibility">Eligibility</a>
      </li>
      <li>
        <a href="#terms-term">Term</a>
      </li>
      <li>
        <a href="#terms-non-commercial-use">Non-Commercial Use by Members</a>
      </li>
      <li>
        <a href="#terms-content-posted">Content Posted at SDSV&amp;Meeting</a>
      </li>
      <li>
        <a href="#terms-copyright-policy">Copyright Policy</a>
      </li>
      <li>
        <a href="#terms-member-disputes">Member Disputes</a>
      </li>
      <li>
        <a href="#terms-calls">Calls and text messages</a>
      </li>
      <li>
        <a href="#terms-disclaimers">Disclaimers</a>
      </li>
    </ol>
    <p><strong /></p>
    <h2 id="terms-eligibility">1. Eligibility</h2>
    <p>
      By registering on SDSV&amp;Meeting, You represent and warrant that You are at least 18 years old . Membership in
      the Service and registration on SDSV&amp;Meeting is void where prohibited. By using SDSV&amp;Meeting, You
      represent and warrant that You have the right, authority, and capacity to enter into this Agreement and to abide
      by all of the terms and conditions of this Agreement. All information supplied when registering MUST be accurate,
      true and complete in all respects.
    </p>
    <p />
    <p>
      In particular, if using a credit or debit card, the cardholder's name MUST be the same as the name used when
      registering with the Website, unless you provide valid and verifiable reasons for it being different. Where the
      identification provided is inaccurate or incomplete, suspension of the account will occur until identity
      verification has been satisfactorily completed.
    </p>
    <p />
    <p>
      You may not authorize others to use Your membership, and You may not assign or otherwise transfer Your account to
      any other person or entity. You must keep your password and all account details secret and confidential as you are
      responsible for use of the Services from your account and any other activities taking place on your account.
    </p>
    <p>Any transactions on your account will stand if your username and password have been entered correctly.</p>
    <h2 id="terms-term">2. Term</h2>
    <p>
      This Agreement will remain in full force and effect while You use SDSV&amp;Meeting and/or are a Member of
      SDSV&amp;Meeting. You may terminate Your membership at any time, for any reason, effective upon receipt of Your
      written notice of termination by SDSV&amp;Meeting customer support staff or closing your account through the
      settings page.
    </p>
    <h2 id="terms-non-commercial-use">3. Non-Commercial Use by Members</h2>
    <p>
      SDSV&amp;Meeting is for the personal use of individual Members only and may not be used in connection with any
      commercial endeavors. Organizations, companies, businesses, and/or other entities may not become Members and are
      not entitled to use the Service or the Website/ Application for any purpose. Illegal and/or unauthorized use of
      the Website/ Application, including collecting usernames and/or email addresses of members by electronic or other
      means for the purpose of sending unsolicited email and unauthorized framing of or linking to the Website/
      Application will be investigated, and appropriate legal action may be taken, including without limitation, civil,
      criminal, and injunctive redress.
    </p>
    <p>
      You hereby grant, represent and warrant that You will not create any databases, websites, applications, software,
      legal entities and services competitive with SDSV&amp;Meeting. You further guarantee and warrant that You will not
      use the Website/Application for any commercial purposes, and/or send or receive funds thorough the
      Website/Application, and/or create accounts with fake usernames. You further guarantee and warrant that You will
      not be paid for using the Website/Application and services, provided on the Website/Application. Such activity
      will be investigated, Your membership may be terminated and appropriate legal action may be taken, including
      without limitation, civil, criminal, and injunctive redress.
    </p>
    <h2 id="terms-content-posted">4. Content Posted at SDSV&amp;Meeting:</h2>
    <ol type="a">
      <li>
        You understand and agree that SDSV&amp;Meeting may at its sole discretion store for review and delete any
        content, messages, photos, videos, audio and video calls or profiles (collectively, &laquo;Content&raquo;) that
        in the sole judgment of SDSV&amp;Meeting violate this Agreement or which might be offensive, illegal, or that
        might violate the rights of, harm, or threaten the safety of other Members or any third parties.
      </li>
      <li>
        You are solely responsible for the Content that You publish or display (hereinafter, &laquo;post&raquo;) via the
        Website/ Application, or transmit to other Members.
      </li>
      <li>
        By posting Content to any public area of the Website/ Application You automatically grant, represent and warrant
        that You have the right to grant to SDSV&amp;Meeting an irrevocable, perpetual, non-exclusive, fully paid,
        worldwide license to use, copy, perform, display, and distribute such information and Content and to prepare
        derivative works of, or incorporate into other works, such information and content, and to grant and authorize
        sublicenses of the foregoing.
      </li>
      <li>
        The following is a partial list of Content that is illegal or prohibited on the Website/ Application.
        SDSV&amp;Meeting reserves the right to investigate and take appropriate legal action at its sole discretion
        against anyone who violates this provision including without limitation removing the offending communication
        from the Service and terminating the membership of such violators. Prohibited Content includes, but is not
        limited to, Content that:

        <ul>
          <li>
            is patently offensive to the online community, such as Content that promotes racism, bigotry, hatred or
            physical harm of any kind against any group or individual;
          </li>
          <li>harasses or advocates harassment of another person;</li>
          <li>
            involves the transmission of &laquo;junk mail&raquo;, &laquo;chain letters&raquo;, or unsolicited mass
            mailing or &laquo;spamming&raquo;;
          </li>
          <li>
            promotes information that You know is defamatory, false, misleading or promotes illegal activities or
            conduct that is abusive, threatening, obscene, defamatory or libelous;
          </li>
          <li>
            promotes an illegal or unauthorized copy of another person&rsquo;s copyrighted work, such as providing
            pirated computer programs or links to them, providing information to circumvent manufacture installed
            copyright protected devices, or providing pirated music or links to pirated music files;
          </li>
          <li>
            contains restricted or password only access pages, or hidden pages or images (those not linked to or from
            another accessible page);
          </li>
          <li>
            publicly displays inappropriate Content or material (involving, for example, nudity, bestiality,
            pornography, graphic violence, or criminal activity);
          </li>
          <li>
            provides material that exploits people under the age of 18 in a sexual or violent manner, or solicits
            personal information from anyone under 18;
          </li>
          <li>
            provides instructional information about illegal activities such as making or buying illegal weapons,
            violating someone&rsquo;s privacy, or providing or creating computer viruses;
          </li>
          <li>
            solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
          </li>
          <li>
            engages in commercial activities and/or sales without SDSV&amp;Meeting&rsquo;s prior written consent such as
            contests, sweepstakes, barter, advertising, and pyramid schemes.
          </li>
        </ul>
      </li>
      <li>
        You must use the Service in a manner consistent with any and all applicable laws and regulations. Your Content
        must comply with applicable international, national and local laws and regulations.
      </li>
      <li>
        You may not include in Your profile any telephone numbers, street addresses, last names, URLs or email
        addresses. You may not include in Your correspondence with other members any URLs, email addresses or telephone
        and fax numbers.
      </li>
      <li>
        You may not engage in advertising to, or solicitation of, other Members to buy or sell any products or services
        through the Service. You may not transmit any chain letters or junk email to other Members. Although
        SDSV&amp;Meeting cannot monitor the conduct of its Members off the Website/ Application, it is also a violation
        of these rules to use any information obtained from the Service in order to harass, abuse, or harm another
        person, or in order to contact, advertise to, solicit, or sell to any Member without their prior explicit
        consent. In order to protect its Members from such advertising or solicitation, SDSV&amp;Meeting reserves the
        right to restrict the number of messages which a Member may send to other Members in any 24-hour period to a
        number which SDSV&amp;Meeting deems appropriate in its sole discretion.
      </li>
      <li>
        You may not collect data from SDSV&amp;Meeting either from authorized and not authorized zones through automated
        means (bots, spiders, scrapers or equivalent means), or by establishing fake user accounts or by anonymous proxy
        servers or equivalent measures, or by circumventing any technical measures to prevent forbidden activity on
        SDSV&amp;Meeting. You may not collect data from SDSV&amp;Meeting authorized zones by any other means including
        manual collection of data.
      </li>
      <li>
        To ensure the quality of the Service provided, Your communication through the Website/ Application may be
        recorded.
      </li>
      <li>
        SDSV&amp;Meeting reserves the right to investigate and take appropriate legal action at its sole discretion
        against anyone who violates this Section including without limitation removing the offending communication from
        the Service and terminating the membership of such violators.
      </li>
    </ol>
    <h2 id="terms-copyright-policy">5. Copyright Policy</h2>
    <p>
      You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary
      information without obtaining the prior written consent of the owner of such proprietary rights.
    </p>
    <p>
      <em>
        Without limiting the foregoing, if You believe that Your work has been copied and posted on the Service in a way
        that constitutes copyright infringement, please supply SDSV&amp;Meeting
      </em>
      <em>
        copyright agent with the following information: an electronic or physical signature of the person authorized to
        act on behalf of the owner of the copyright interest; a description of the copyrighted work that You claim has
        been infringed;
      </em>
      <em>
        a description of where the material that You claim is infringing on such copyright is located on the Website/
        Application; Your address, telephone number, and email address; a written statement by You that You have a good
        faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement
        by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are
        the copyright owner or authorized to act on the copyright owners behalf.
      </em>
    </p>
    <h2 id="terms-member-disputes">6. Member Disputes</h2>
    <p>
      You are solely responsible for Your interactions with other SDSV&amp;Meeting Members. SDSV&amp;Meeting reserves
      the right, but has no obligation, to monitor disputes between You and other Members.
    </p>
    <h2 id="terms-calls">7. Calls and text messages</h2>
    <p>
      By providing Your phone number(s), you confirm that the number(s) belongs to you and you expressly consent to
      receive non-marketing and marketing text messages from SDSV&amp;Meeting, at the telephone number(s) that you
      provide. Your phone number will only be visible to you.
      <br />
    </p>
    <h2 id="terms-disclaimers">8. Disclaimers</h2>
    <p>
      SDSV&amp;Meeting PROVIDES THE SERVICE ON AN AS IS BASIS AND GRANTS NO WARRANTIES OF ANY KIND, EXPRESSED, IMPLIED
      OR STATUTORY, IN ANY COMMUNICATION WITH ITS REPRESENTATIVES, OTHERWISE WITH RESPECT TO THE SERVICE.
      SDSV&amp;Meeting SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
      PURPOSE. FURTHER, SDSV&amp;Meeting DOES NOT WARRANT THAT YOUR USE OF THE SERVICE WILL BE SECURE, UNINTERRUPTED,
      ALWAYS AVAILABLE OR ERROR-FREE, OR THAT THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT ANY DEFECTS IN THE SERVICE
      WILL BE CORRECTED. SDSV&amp;Meeting DISCLAIMS LIABILITY FOR, AND NO WARRANTY IS MADE WITH RESPECT TO, CONNECTIVITY
      AND AVAILABILITY.
    </p>
    <p>
      Although each member must agree to the Agreement, SDSV&amp;Meeting cannot guarantee that each member is at least
      the required minimum age, nor does it accept responsibility or liability for any content, communication or other
      use or access of the Service by persons under the age of 18 in violation of this Agreement. Also, it is possible
      that other members or users (including unauthorized users, or &laquo;hackers&raquo;) may post or transmit
      offensive or obscene materials through the Service and that You may be involuntarily exposed to such offensive or
      obscene materials. It also is possible for others to obtain personal information about You due to Your use of the
      Service. Those others may use Your information for purposes other than what You intended.
    </p>
    <p>
      SDSV&amp;Meeting is not responsible for the use of any personal information that You disclose on the Service.
      Please carefully select the type of information that You post on the Service or release to others.
    </p>
    <p>
      SDSV&amp;Meeting DISCLAIMS ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER
      MEMBERS OR USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING THE USE OF THE
      SERVICE OR OTHERWISE.
    </p>
    <p>
      SDSV&amp;Meeting is not responsible for any incorrect or inaccurate Content posted on the Website/ Application or
      in connection with the Service, whether caused by users of the Website/ Application, Members or by any of the
      equipment or programming associated with or utilized in the Service.
    </p>
    <p>
      SDSV&amp;Meeting is not responsible for the conduct, whether online or offline, of any user of the Website/
      Application or Member of the Service.
    </p>
    <p>
      SDSV&amp;Meeting assumes no responsibility for any error, omission, interruption, deletion, defect, delay in
      operation or transmission, communications line failure, theft or destruction or unauthorized access to, or
      alteration of, user or Member communications.
    </p>
    <p>
      SDSV&amp;Meeting is not responsible for any problems or technical malfunction of any telephone network or lines,
      computer online systems, servers or providers, computer equipment, software, failure of email or players on
      account of technical problems or traffic congestion on the Internet or at any Websites/ Applications or
      combination thereof, including injury or damage to users and/or Members or to any other persons computer related
      to or resulting from participating or downloading materials in connection with the Web and/or in connection with
      the Service.
    </p>
    <p>
      Under no circumstances will SDSV&amp;Meeting be responsible for any loss or damage, including personal injury or
      death, resulting from anyone&rsquo;s use of the Website/ Application or the Service, any Content posted on the
      Website/ Application or transmitted to Members, or any interactions between users of the Website/ Application,
      whether online or offline.
      <br />
    </p>
    <p>
      <u>
        This Agreement, accepted upon use of the Website/ Application and further affirmed by becoming a Member of the
        Service, contains the entire Agreement between You and SDSV&amp;Meeting
      </u>
      <u>
        regarding the use of the Website/ Application and/or the Service. If any provision of this Agreement is held
        invalid, the remainder of this Agreement shall continue in full force and effect.
      </u>
    </p>
  </div>
</template>
<script setup lang="ts"></script>
