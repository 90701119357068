import { useHandleRequest } from '~/composables/handler-requests';

const useNotificationStore = defineStore('notifications-store', () => {
  const meta = ref<PaginationMetaCursor>({});
  const notifications = ref<NotificationItem[]>([]);
  const loading = ref<boolean>(false);
  const isFirstLoad = ref<boolean>(true);
  const apiRoutes = useApiRoutes();
  const { onUpdateUser } = useAuthUser();
  const handleRequest = useHandleRequest();
  const isNotifications = computed(() => notifications.value.length);

  const isLastPage = computed(() => {
    return !meta.value.next_cursor;
  });

  const fetchNotifications = async (next?: boolean) => {
    loading.value = true;

    const params = {
      per_page: 10,
      cursor: next ? meta.value.next_cursor : undefined
    };
    const res = await handleRequest(apiRoutes.notifications.index(params));

    if (!res.isError) {
      if (next) {
        notifications.value.push(...res.data.data);
      } else {
        notifications.value = res.data.data;
      }

      meta.value = res.data.meta;
    }

    loading.value = false;
    isFirstLoad.value = false;

    return true;
  };

  const updateNotificationsExists = (val: boolean) => {
    onUpdateUser({
      unread_notifications_exists: val
    });
  };

  const checkAndUpdateNotificationsExists = () => {
    if (!notifications.value.some(notification => !notification.read_at)) {
      updateNotificationsExists(false);
    }
  };

  const deleteNotification = async (notificationId: string) => {
    notifications.value = removeItemArray(notifications.value, notificationId);
    checkAndUpdateNotificationsExists();
  };

  const makeNotificationRead = async (notificationId: string) => {
    notifications.value = updateItemArray(notifications.value, { id: notificationId, read_at: new Date() });
    checkAndUpdateNotificationsExists();
  };

  const makeAllNotificationRead = () => {
    notifications.value = useMap<NotificationItem, NotificationItem>(notifications.value, notification => {
      return { ...notification, read_at: new Date() };
    });
    updateNotificationsExists(false);
  };

  const addNotification = (notification: NotificationItem) => {
    notifications.value = upsert(notifications.value, notification, { unshift: true });
    updateNotificationsExists(true);
  };

  const onLoadMore = async () => {
    if (!isLastPage.value) {
      await fetchNotifications(true);
    }
  };

  const reset = () => {
    updateNotificationsExists(false);
    loading.value = false;
    notifications.value = [];
    meta.value = {};
    isFirstLoad.value = true;
  };

  return {
    meta,
    loading,
    isFirstLoad,
    notifications,
    isNotifications,
    isLastPage,

    reset,
    deleteNotification,
    makeNotificationRead,
    fetchNotifications,
    makeAllNotificationRead,
    onLoadMore,
    addNotification
  };
});

export default useNotificationStore;
