export default defineI18nConfig(() => ({
  legacy: false,
  missingWarn: false, // Hide console warn
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'standard'
      }
    }
  }
}));
