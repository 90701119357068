import { type Ref, type ComputedRef, computed, nextTick } from 'vue';
import { createError, navigateTo, useNuxtApp, useRoute } from '#app';
import getValueByKey from '../../utils/getValueByKey';
import { useSanctumClient } from './useSanctumClient';
import { useSanctumUser } from './useSanctumUser';
import useAuthRedirectCookie from './useAuthRedirectCookie';
import { useSanctumConfig } from './useSanctumConfig';

export interface SanctumAuth<T> {
  user: Ref<T | null>;
  isAuthenticated: ComputedRef<boolean>;
  isVerify: ComputedRef<boolean>;
  login: (credentials: Record<string, any>) => Promise<void>;
  logout: (checkAuth?: boolean) => Promise<void>;
  refreshIdentity: () => Promise<void>;
  parseUserData: (data: any) => T;
}

/**
 * Provides authentication methods for Laravel Sanctum
 *
 * @param T Type of the user object
 */
export const useSanctumAuth = <T>(): SanctumAuth<T> => {
  const nuxtApp = useNuxtApp();

  const user = useSanctumUser<T>();
  const client = useSanctumClient();
  const options = useSanctumConfig();
  const loginRedirectCookie = useAuthRedirectCookie();
  const isAuthenticated = computed(() => {
    return user.value !== null;
  });

  const isVerify = computed(() =>
    options.checkVerifyPath ? !!getValueByKey(user.value, options.checkVerifyPath) : true
  );

  const parseUserData = (data: any) => getValueByKey(data, options.userPath);

  async function refreshIdentity() {
    user.value = parseUserData(await client<T>(options.endpoints.user));
  }

  /**
   * Calls the login endpoint and sets the user object to the current state
   *
   * @param credentials Credentials to pass to the login endpoint
   */
  async function login(credentials: Record<string, any>) {
    const currentRoute = useRoute();

    if (isAuthenticated.value) {
      if (!options.redirectIfAuthenticated) {
        throw new Error('User is already authenticated');
      }

      if (options.redirect.onLogin === false || options.redirect.onLogin === currentRoute.path) {
        return;
      }

      await nuxtApp.runWithContext(() => navigateTo(options.redirect.onLogin as string));
    }

    const res = await client(options.endpoints.login, {
      method: 'post',
      body: credentials
    });

    if (options.autoFetch) {
      await refreshIdentity();
    } else {
      user.value = parseUserData(res);
    }
    await nextTick();

    if (!isVerify.value) {
      const redirect = options.redirect.onNotVerified as string;
      if (redirect) {
        await nuxtApp.runWithContext(() => navigateTo(options.redirect.onNotVerified as string));
        return;
      }
      throw createError({ statusCode: 403 });
    }

    if (options.redirect.keepRequestedRoute) {
      const requestedRoute = loginRedirectCookie.value;
      if (requestedRoute && requestedRoute !== currentRoute.path) {
        await nuxtApp.runWithContext(() => {
          useAuthRedirectCookie().value = null;
          return navigateTo(requestedRoute);
        });
        return;
      }
    }

    if (options.redirect.onLogin && currentRoute.path !== options.redirect.onLogin) {
      await nuxtApp.runWithContext(() => navigateTo(options.redirect.onLogin as string));
    }
  }

  /**
   * Calls the logout endpoint and clears the user object
   */
  async function logout(checkAuth = true) {
    if (checkAuth && !isAuthenticated.value) {
      throw new Error('User is not authenticated');
    }
    const currentRoute = useRoute();
    await client(options.endpoints.logout, { method: 'post' });

    user.value = null;

    if (options.redirect.onLogout === false || currentRoute.path === options.redirect.onLogout) {
      return;
    }

    await nuxtApp.runWithContext(() => navigateTo(options.redirect.onLogout as string));
  }

  return {
    user,
    isAuthenticated,
    isVerify,
    parseUserData,
    login,
    logout,
    refreshIdentity
  } as SanctumAuth<T>;
};
