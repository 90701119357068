import revive_payload_client_WMBYwdvYp5 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rI8lQopHj1 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_htVmJd4ro3 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l8mHAbYmUh from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_@types+node@22.3.0_sass@1.77._jcwaen32pyaknp2xzovgwvrybu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_vi455W7XJK from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PIE0CAtLRC from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_fkdoozsajC from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PsKOsp1BUD from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BxXQl8VI1S from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@3.29.4_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_FS0b7L019k from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2c7cnukityy3di34vpctte3sja/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_cUSWohc9UT from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_VXpxw9XiVR from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.4_rollup@3.29.4_vue@3.4.38_typescript@5.5.4_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_jqD0tIk8ea from "/home/runner/work/dating-frontend/dating-frontend/modules/nuxt-auth-sanctum/runtime/plugin.ts";
import http_plugin_nuxt_33mPHZfM7U from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.4_nuxi@3.12.0_rollup@3.29.4_sass@1.77.8_typescript@5.5.4_v_umiuzybvdwo4aul73353awypw4/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import switch_locale_path_ssr_6I7o9mBTJw from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.3.4_rollup@3.29.4_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_xKZLBbD5W2 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.3.4_rollup@3.29.4_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_yhN4LP21UJ from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-viewport@2.1.5_magicast@0.3.4_rollup@3.29.4_vue@3.4.38_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_dfdbYF3f67 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.3.4_rollup@3.29.4/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import pwa_icons_WLjmI2IfZj from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_@types+node@22.3.0_sass@1.77.8_t_koeqazfabkcaoyuy744mjcvybu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_29ySN8GhNL from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_@types+node@22.3.0_sass@1.77.8_t_koeqazfabkcaoyuy744mjcvybu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_ekOPgLLmfd from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_h3@1.12.0_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_og72kc6sqputzr5p2qnoqnh3ra/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_ZTsqEnNZeh from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_h3@1.12.0_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_og72kc6sqputzr5p2qnoqnh3ra/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_ZlDiSBioKK from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_@types+node@22.3.0_sa_y3krpdloviydxuwluxa66fejxi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_7xaI0KjSEV from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.4_rollup@3.29.4_vite@5.4.1_@types+node@22.3.0_sa_y3krpdloviydxuwluxa66fejxi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_vYgEPRmKjY from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@3.29.4_typesc_fw4khexb4r7zhn4sm5bm5syz6q/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_routes_Q0W49V3wqt from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/api-routes.ts";
import custom_pwa_register_client_fOvh2D2KfW from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/custom-pwa-register.client.ts";
import dompurify_R8xb7bJPqP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/dompurify.ts";
import floating_1Jsyq3dLZq from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/floating.ts";
import handle_app_color_mn7Bwa8Xck from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/handle-app-color.ts";
import modal_saNsw8xGBA from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/modal.ts";
import pusher_js_client_2TZYA6fBVP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/pusher-js.client.ts";
import select_fkZYP3esEZ from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/select.ts";
import toast_notification_ycBNrvS44W from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/toast-notification.ts";
import plugin_client_QHzhVBB2Ll from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_WMBYwdvYp5,
  unhead_rI8lQopHj1,
  router_htVmJd4ro3,
  _0_siteConfig_l8mHAbYmUh,
  payload_client_vi455W7XJK,
  navigation_repaint_client_PIE0CAtLRC,
  check_outdated_build_client_fkdoozsajC,
  chunk_reload_client_PsKOsp1BUD,
  plugin_vue3_BxXQl8VI1S,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FS0b7L019k,
  plugin_client_cUSWohc9UT,
  plugin_client_VXpxw9XiVR,
  plugin_jqD0tIk8ea,
  http_plugin_nuxt_33mPHZfM7U,
  switch_locale_path_ssr_6I7o9mBTJw,
  i18n_xKZLBbD5W2,
  plugin_client_yhN4LP21UJ,
  plugin_dfdbYF3f67,
  pwa_icons_WLjmI2IfZj,
  pwa_client_29ySN8GhNL,
  titles_ekOPgLLmfd,
  defaults_ZTsqEnNZeh,
  siteConfig_ZlDiSBioKK,
  inferSeoMetaPlugin_7xaI0KjSEV,
  plugin_vYgEPRmKjY,
  api_routes_Q0W49V3wqt,
  custom_pwa_register_client_fOvh2D2KfW,
  dompurify_R8xb7bJPqP,
  floating_1Jsyq3dLZq,
  handle_app_color_mn7Bwa8Xck,
  modal_saNsw8xGBA,
  pusher_js_client_2TZYA6fBVP,
  select_fkZYP3esEZ,
  toast_notification_ycBNrvS44W,
  plugin_client_QHzhVBB2Ll
]