<template>
  <div class="flex flex-wrap gap-2 lg:gap-3 justify-center mb-6 lg:mb-0">
    <ui-button
      variant="secondary"
      size="md"
      icon-left="google"
      icon-left-type="custom"
      icon-left-filled
      class="w-full md:w-auto"
      text="Continue with Google"
      @click="onLogin('google')"
    />
    <ui-button
      variant="secondary"
      size="md"
      class="w-full md:w-auto"
      icon-left="facebook"
      icon-left-filled
      icon-left-type="custom"
      text="Continue with Facebook"
      @click="onLogin('facebook')"
    />
  </div>
</template>

<script setup lang="ts">
  const onLogin = useLogin();
</script>
