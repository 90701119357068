function getValueByKey<D = any>(entry: any, key: string): D | void {
  if (typeof entry !== 'object' || entry === null) {
    return;
  }

  const keys = key.split('.');
  let value: any = entry;

  for (const k of keys) {
    if (typeof value !== 'object' || value === null || !(k in value)) {
      return;
    }
    value = value[k];
  }

  return value;
}

export default getValueByKey;
