import { defineNuxtRouteMiddleware, navigateTo, createError } from '#app';
import { useSanctumAuth } from '../composables/useSanctumAuth';
import { useSanctumConfig } from '../composables/useSanctumConfig';

export default defineNuxtRouteMiddleware(() => {
  const { isAuthenticated } = useSanctumAuth();
  const options = useSanctumConfig();

  if (!isAuthenticated.value) {
    return;
  }

  const endpoint = options.redirect.onGuestOnly;

  if (endpoint === false) {
    throw createError({ statusCode: 403 });
  }

  return navigateTo(endpoint, { replace: true });
});
