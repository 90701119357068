<template>
  <ui-button
    color="dark"
    :class="{ capitalize: !isSmall }"
    :to="to"
    :variant="isSmall ? 'secondary' : 'primary-light'"
    :href="href"
    :icon-left="iconName"
    :size="isSmall ? 'sm-icon' : 'lg-icon'"
    @click="onClick"
  />
</template>

<script setup lang="ts">
  type Props = {
    to?: string | object;
    href?: string;
    iconName?: string;
    isSmall?: boolean;
  };
  const props = withDefaults(defineProps<Props>(), {
    iconName: 'chevron_left'
  });
  const router = useRouter();

  const onClick = () => {
    if (props.to || props.href) {
      return;
    }
    router.back();
  };
</script>
