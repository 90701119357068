const storageKey = 'moods';
const useGetMoods = async () => {
  const apiRoutes = useApiRoutes();

  const {
    pending,
    error,
    data: moods,
    refresh
  } = await useCacheAsyncData<Mood[]>(
    storageKey,
    async () => {
      const res = await apiRoutes.moods.index();

      return res._data.data;
    },
    {
      default: () => [],
      server: false
    }
  );

  return {
    moods,
    pending,
    error,
    refresh
  };
};

export default useGetMoods;
