<template>
  <div class="relative flex-1 flex items-center justify-center p-5">
    <common-offline />
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'empty'
  });
  // Todo: remove this after the fix with offline scripts loading
  useHead({
    script: [
      {
        type: 'text/javascript',
        innerHTML: `
  document.addEventListener('DOMContentLoaded', () => {
    const REFRESH_TIME = 3000;
    const goToOnline = () => {
      if (window.history.state.back && !window.history.state?.back?.includes('/offline')) {
        window.location.href = window.history.state.back;
        return;
      }
      console.log('profile');
      window.location.href = '/dashboard/profile';
    };

    if (navigator && navigator.onLine) {
      goToOnline();
    } else {
      setInterval(async () => {
        try {
          const response = await fetch('/support');
          if (response.status >= 200 && response.status < 500) {
            goToOnline();
          }
        } catch (e) {
          console.error('Fetch error', e);
        }
      }, REFRESH_TIME);
    }
  });
      `,
        hid: 'inline-offline-script'
      }
    ]
  });
</script>
