<template>
  <ui-container
    :spacing="isPage ? 'none' : 'base'"
    class="bg-card text-body-text md:py-14 py-6 rounded-lg min-h-64 flex flex-col justify-center shrink-0"
  >
    <div
      v-if="!hideImage"
      class="flex justify-center mb-3"
    >
      <ui-error-icon :message="is404 ? '404' : 'Error'" />
    </div>
    <div class="max-w-2xl text-center mx-auto">
      <ui-typography
        :tag="isPage ? 'h1' : 'h2'"
        variant="h3"
        class="text-balance break-words"
      >
        {{ is404 ? $t('Whoops!! Something went wrong.') : prepareError }}
      </ui-typography>
    </div>
    <div
      v-if="isPage"
      class="sm:mt-12 mt-7 text-center"
    >
      <ui-button
        text="Back to Homepage"
        @click="onClearError"
      />
    </div>
  </ui-container>
</template>

<script setup lang="ts">
  type Props = {
    error: any;
    isPage?: boolean;
    hideImage?: boolean;
  };
  type Emits = {
    (e: 'clearError'): boolean;
  };
  const props = defineProps<Props>();
  const emit = defineEmits<Emits>();

  const { routerPush } = useRouterLocale();

  const getMessageErrorByResponse = useGetMessageErrorByResponse();
  const statusCode = computed<number>(() => {
    const error: Keyable = unref(props.error) || {};
    return +(error.status || error.statusCode || 500);
  });

  const is404 = computed(() => statusCode.value === 404);

  const prepareError = computed(() => {
    return getMessageErrorByResponse(unref(props.error));
  });

  const onClearError = async () => {
    await routerPush('/');
    emit('clearError');
  };
</script>
