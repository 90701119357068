import type { FetchInstance } from '@refactorjs/ofetch';
import { excludeMethods } from '~/utils/api/helpers';

@excludeMethods(['index'])
class ApiProfile extends ApiResources {
  constructor(api: FetchInstance) {
    super(api, 'profile', { isFile: true });
  }

  getPrivateInterests = () => this.api.get(this.url + '/interests');
  resendConfirmEmail = () => this.api.post(this.url + '/email/resend');
  saveFcmTokens = (body: { token: string }) => this.api.post(this.url + '/fcm-tokens', { body });
  getPrivateRooms = (query: Keyable) => {
    return this.api.get(this.url + '/rooms', { query });
  };
}

export default ApiProfile;
