import type { FetchConfig } from '@refactorjs/ofetch';
import createApiRoutes from '~/services';

export default defineNuxtPlugin((nuxtApp: any) => {
  const { BASE_API_URL } = useRuntimeConfig().public;

  const options: FetchConfig = {
    headers: {
      accept: 'application/json'
    },
    retry: false,
    credentials: 'include',
    baseURL: BASE_API_URL
  };

  if (nuxtApp.ssrContext) {
    const event = useRequestEvent(nuxtApp);
    const { cookie } = usePick(event?.node.req.headers, ['cookie']);

    options.headers = {
      ...options.headers,
      cookie: cookie || '',
      referer: useRequestURL().origin,
      'X-Xsrf-Token': useCookie('XSRF-TOKEN').value || ''
    };
  }

  const api = nuxtApp.$http.create(options);

  const addCookieAuthRedirect = useAddCookieAuthRedirect();
  const { routerReplace } = useRouterLocale();
  const { user } = useSanctumAuth();

  // Todo: added auto reset csrf and add block duplicate requests
  api.onResponseError(async (error: any) => {
    if (error.response?.status === 401) {
      addCookieAuthRedirect();
      user.value = null;
      await routerReplace({ name: 'login' });
    }
    throw error;
  });

  return {
    provide: {
      apiRoutes: createApiRoutes(api),
      api
    }
  };
});
