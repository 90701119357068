import { FetchInstance } from '@refactorjs/ofetch';
import { excludeMethods } from '~/utils/api/helpers';

@excludeMethods(['show', 'create'])
class ApiNotifications extends ApiResources {
  constructor(api: FetchInstance) {
    super(api, 'notifications');
  }
}

export default ApiNotifications;
