<template>
  <div class="flex items-center justify-between gap-3">
    <ui-typography>
      <b>{{ t('Action Blocked') }}:</b>
      {{ t('Your subscription limit has been reached.') }}
      <a
        href="/dashboard/subscriptions"
        class="underline text-inherit"
        @click.prevent="onUpgrade"
      >
        {{ t('Click here to upgrade plan.') }}
      </a>
    </ui-typography>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{ closeToast(): void }>();
  const { t } = useNuxtApp().$i18n;
  const { routerPush } = useRouterLocale();
  const onUpgrade = () => {
    routerPush({ name: 'dashboard-subscriptions' });
    props.closeToast();
  };
</script>
