import type { TRoomAdminPermission } from '~/globals/rooms';

type KRoomAdminPermission = keyof typeof RoomAdminPermission;

type RoomAdminHasPermission = {
  [key in KRoomAdminPermission]: boolean;
};

const useRoomStore = defineStore('room', () => {
  const room = ref<Room>();
  const loading = ref(false);
  const limitToasts = useLimitToasts();
  const roomId = computed(() => room.value?.id || 0);
  const apiRoutes = useApiRoutes();
  const myRoomStore = useMyRoomStore();

  const userStatus = computed(() => {
    return getUserStatusForRoom(room);
  });

  const isVisibleHistory = computed(() => !!room.value?.is_visible_history);

  const disableFetchMessages = computed(() => !roomId.value || (!isVisibleHistory.value && userStatus.value.member));
  const roomType = computed(() => {
    const roomType = room.value?.type;
    return {
      room: roomType === RoomType.Room,
      roomIdea: roomType === RoomType.RoomIdea
    };
  });

  const checkHasPermission = (permission: TRoomAdminPermission) => {
    if (userStatus.value.owner) {
      return true;
    }
    if (userStatus.value.admin) {
      return useIncludes(room.value?.user_status?.permissions, permission);
    }
    return false;
  };

  const userPermission = computed(() => {
    return useTransform(
      RoomAdminPermission,
      (acc, val, key) => {
        acc[key as KRoomAdminPermission] = checkHasPermission(val);

        return acc;
      },
      {} as RoomAdminHasPermission
    );
  });

  let abortController: AbortController;
  const fetchRoom = async (roomId: number) => {
    abortController?.abort();
    abortController = new AbortController();
    const res = await apiRoutes.rooms.show<{ data: Room }>(roomId, undefined, {
      timeout: 0,
      signal: abortController.signal
    });
    room.value = res._data?.data;
    myRoomStore.updateRoom(room.value);
    return true;
  };

  const reset = () => {
    loading.value = false;
    room.value = undefined;
  };

  const updateRoom = (updatedRoom: Partial<Room>) => {
    room.value = { ...room.value, ...updatedRoom } as Room;

    myRoomStore.updateRoom(room.value);
  };

  const updateRoomStatus = (userStatus?: RoomUserStatusType) => {
    if (!room.value) return;
    room.value.user_status = userStatus;
  };

  const onLeaveRoom = async (id = roomId.value) => {
    await apiRoutes.rooms.leave(id);

    myRoomStore.deleteRoomByType(id, RoomFilterType.Member);
  };

  const onJoinRoom = async (joinRoom: Room | RoomPreview | undefined = room.value) => {
    if (joinRoom) {
      try {
        await apiRoutes.rooms.join(joinRoom.id);
        myRoomStore.addRoom(joinRoom, RoomFilterType.Member);
      } catch (error) {
        limitToasts(error);
        throw error;
      }
    }
  };
  const onDeleteRoom = async () => {
    await apiRoutes.rooms.delete(roomId.value);

    myRoomStore.deleteRoom(roomId.value);
  };

  const onApproveUser = async (userId: number, currentRoomId = roomId.value) => {
    await apiRoutes.rooms.approveUser(currentRoomId, userId);
  };
  const onRejectUser = async (userId: number, currentRoomId = roomId.value) => {
    await apiRoutes.rooms.rejectUser(currentRoomId, userId);
  };

  const calcCount = (oldCount = 0, membersCount = 0, isDec = false) => {
    return isDec ? Math.max(oldCount - 1, 0) : Math.min(oldCount + 1, membersCount);
  };

  const setOnlineCount = (currentRoomId: number, isDec = false) => {
    if (currentRoomId === roomId.value) {
      updateRoom({
        online_count: calcCount(room.value?.online_count, room.value?.members_count, isDec)
      });
      return;
    }
    const myRoom = myRoomStore.findRoom(currentRoomId);
    if (myRoom) {
      myRoomStore.updateRoom({
        id: myRoom.id,
        online_count: calcCount(myRoom.online_count, myRoom.members_count, isDec)
      });
    }
  };

  return {
    room,
    loading,
    roomId,
    userPermission,
    userStatus,
    roomType,
    isVisibleHistory,
    disableFetchMessages,
    reset,
    fetchRoom,
    updateRoomStatus,
    updateRoom,
    onDeleteRoom,
    onLeaveRoom,
    onJoinRoom,
    onApproveUser,
    onRejectUser,
    setOnlineCount
  };
});

export default useRoomStore;
