const getUserStatusForRoom = (room: MaybeRef<Room | RoomPreview | RoomUser | undefined>) => {
  const status = unref(room)?.user_status?.status;
  return {
    guest: !status,
    owner: status === RoomUserStatus.Owner,
    admin: status === RoomUserStatus.Admin,
    member: status === RoomUserStatus.Member,
    waiting: status === RoomUserStatus.Waiting
  };
};

const useGoToRoom = () => {
  const { routerPush } = useRouterLocale();

  return (room: Room | RoomPreview) => {
    const userStatus = getUserStatusForRoom(room);
    if (room.type === RoomType.RoomIdea || userStatus.guest || userStatus.waiting) {
      return routerPush({
        name: 'dashboard-rooms-roomId',
        params: {
          roomId: room.id
        }
      });
    }
    return routerPush({
      name: 'dashboard-rooms-roomId-chat',
      params: {
        roomId: room.id
      }
    });
  };
};

const useCurrentRoomPermissions = () => {
  const roomStore = useRoomStore();
  const onlyPublicRoom = computed(() => roomStore.userStatus.guest || roomStore.userStatus.waiting);
  const isShowChat = computed(() => !onlyPublicRoom.value && roomStore.room?.type === RoomType.Room);
  const hasEditable = computed(() => roomStore.userStatus.admin || roomStore.userStatus.owner);

  return {
    onlyPublicRoom,
    isShowChat,
    hasEditable
  };
};

const useRoomRedirect = () => {
  const roomStore = useRoomStore();
  const route = useRoute();
  const { isAuthenticated } = useSanctumAuth();
  const getRouteBaseName = useRouteBaseName();

  if (!isAuthenticated.value || getRouteBaseName(route as any) === 'dashboard-rooms-roomId') {
    return;
  }

  const { routerReplace } = useRouterLocale();
  if (roomStore.roomId) {
    return routerReplace({
      name: 'dashboard-rooms-roomId',
      params: { roomId: roomStore.roomId }
    });
  }
  return routerReplace({
    name: 'dashboard-rooms'
  });
};

export { getUserStatusForRoom, useGoToRoom, useCurrentRoomPermissions, useRoomRedirect };
