<template>
  <ui-button
    v-bind="props"
    :loading="loading || innerLoading"
    @click="onClick"
  >
    <slot />
  </ui-button>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<AsyncButtonProps>(), {
    checkConfirm: () => true
  });

  const emit = defineEmits(['success']);
  const { loading: innerLoading, handler } = useHandlerErrorAndLoadingClient();
  const onClick = async () => {
    const isConfirm = await props.checkConfirm();
    if (!props.request || !isConfirm) {
      return;
    }
    const { isError, data } = await handler(async () => {
      return await props.request?.();
    });
    if (!isError) {
      emit('success', data);
    }
  };
</script>
