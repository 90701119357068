import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(FloatingVue, {
    themes: {
      tooltip: {
        placement: 'top'
      },
      'dropdown-full': {
        $extend: 'dropdown'
      },
      custom: {
        $extend: 'dropdown'
      }
    }
  });
});
