<template>
  <svg
    class="animate-spin h-5 w-5 max-w-full max-h-full"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      class="opacity-25"
      stroke="currentColor"
    />
    <path
      class="opacity-75"
      fill="currentColor"
      d="M.724 16.104A12 12 0 0 1 11.58.007l.067 1.92a10.08 10.08 0 0 0-9.12 13.52l-1.804.657Z"
    />
  </svg>
</template>
