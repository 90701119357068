<template>
  <div class="flex items-center justify-between gap-3">
    <ui-typography>{{ t('New content available, click on reload button to update.') }}</ui-typography>
    <div class="shrink-0">
      <ui-button
        size="xs"
        variant="secondary"
        icon-left="upgrade"
        icon-class-left="text-base mr-0.5"
        class="shrink-0 w-24"
        @click="onUpdate"
      >
        {{ t('Update') }}
      </ui-button>
    </div>
  </div>
</template>

<script setup lang="ts">
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n;
  const onUpdate = async () => {
    nuxtApp.$toast.remove('upgradeApp');
    await nuxtApp.$pwa?.updateServiceWorker();
    window.location.reload();
  };
</script>
