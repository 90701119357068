import type { TSocialAuthProvider } from '~/globals/auth';

const useAuthPending = () => {
  return useState('authPending', () => false);
};

const useAuthRedirect = () => {
  const cookie = useAuthRedirectCookie();
  const localePath = useLocalePath();
  const baseRedirectPath = localePath({ name: 'dashboard-profile' });
  return async () => {
    try {
      await navigateTo((cookie.value || baseRedirectPath) as string);
      cookie.value = '';
    } catch (e) {
      navigateTo(baseRedirectPath);
    }
  };
};

const useSetLoginUser = () => {
  const user = useSanctumUser<User>();
  const onAuthRedirect = useAuthRedirect();
  return async (userData: User, enabledRedirect = true) => {
    user.value = userData;
    if (enabledRedirect) {
      await onAuthRedirect();
    }
  };
};

const useLogin = (enabledRedirect = true) => {
  const { login } = useSanctumAuth<User>();
  const onSocialLogin = useSanctumSocialAuth();
  const pending = useAuthPending();
  const getMessageErrorByResponse = useGetMessageErrorByResponse();
  const toast = useToast();
  const onSetLoginUser = useSetLoginUser();
  const apiRoutes = useApiRoutes();

  const handelMessage = async (eventData: any) => {
    if (eventData.authError) {
      toast.error(getMessageErrorByResponse(eventData.authError));
    } else if (eventData.isLoggedIn) {
      const res = await apiRoutes.profile.show();
      await onSetLoginUser(res._data.data, enabledRedirect);
    }
  };

  return async (reqData: BodyLogin | TSocialAuthProvider) => {
    let res;
    try {
      pending.value = true;
      if (isString(reqData)) {
        onSocialLogin(reqData, handelMessage);
      } else {
        res = await login(reqData);
      }
    } finally {
      pending.value = false;
    }
    return res;
  };
};

const useLogout = () => {
  const { logout } = useSanctumAuth<User>();
  const pending = useAuthPending();

  const onLogout = async (checkAuth?: boolean) => {
    pending.value = true;
    let res;
    try {
      res = await logout(checkAuth);
    } finally {
      pending.value = false;
    }

    return res;
  };
  return [onLogout, pending] as const;
};

const useCurrentUserName = () => {
  const user = useSanctumUser<User>();
  return computed(() => getUserFullName(user.value));
};

const useForgotPassword = (showToast?: boolean) => {
  const { handler, loading } = useHandlerErrorAndLoadingClient();
  const apiRoutes = useApiRoutes();
  const user = useSanctumUser<User>();
  const { t } = useI18n();
  const successToast = computed(() => {
    return showToast ? t(MessagesSuccess.forgotPassword) : undefined;
  });
  const onForgotPassword = async (email = user.value?.email) => {
    if (email) {
      await handler(apiRoutes.auth.forgotPassword({ email }), {
        toast: { success: successToast.value },
        showServerError: true
      });
    }
  };
  return {
    onForgotPassword,
    loading
  };
};

const useValidUser = () => {
  const user = useSanctumUser<User>();
  return computed(() => {
    return !!user.value?.interest_ids?.length;
  });
};

const useAuthUser = () => {
  const user = useSanctumUser<User>();

  const setUser = (newUser: User) => {
    user.value = newUser;
  };

  const onUpdateUser = (userFields: Partial<User>) => {
    if (user.value) {
      setUser(mergeExcludeArray(user.value, userFields));
    }
  };

  const subscriptionLimitations = computed(() => user.value?.subscription_plan?.limitations);

  return {
    user,
    subscriptionLimitations,
    setUser,
    onUpdateUser
  };
};

export {
  useLogin,
  useLogout,
  useAuthRedirect,
  useAuthPending,
  useSetLoginUser,
  useCurrentUserName,
  useForgotPassword,
  useValidUser,
  useAuthUser
};
