<template>
  <common-profile-settings-content-wrap
    :breadcrumbs="breadcrumbs"
    full
  >
    <common-content-terms class="max-h-screen overflow-auto" />
  </common-profile-settings-content-wrap>
</template>
<script setup lang="ts">
  const breadcrumbs = useDashboardSettingsBreadcrumbs([
    {
      to: { name: 'dashboard-settings-terms' },
      disabled: false,
      name: 'Terms'
    }
  ]);
  useSeoMeta({
    title: 'Terms'
  });
</script>
