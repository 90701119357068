<template>
  <ui-chip
    size="sm"
    :variant="isActive ? 'primary' : 'secondary-outline'"
    :icon="statusConfig.icon"
    :text="statusConfig.text"
    :tag="tag"
  />
</template>

<script setup lang="ts">
  type Props = {
    isPublic: boolean;
    isActive?: boolean;
    tag?: keyof HTMLElementTagNameMap;
  };
  const props = defineProps<Props>();

  const statusConfig = computed(() => {
    if (props.isPublic) {
      return {
        icon: 'check',
        text: 'Public'
      };
    }
    return {
      icon: 'lock',
      text: 'Private'
    };
  });
</script>
