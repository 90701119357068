import type { PartialDeep } from 'type-fest';

const useGetMessageErrorByResponse = () => {
  const { t } = useNuxtApp().$i18n;
  return (error: any): string => {
    const status = error?.response?.status || error?.statusCode;
    if (status === 401) {
      return t(MessagesError.unauthenticated);
    }
    if (status === 403) {
      return t(MessagesError.forbidden);
    }
    let message: string =
      error?.response?._data?.message || error?.response?.message || error?.statusMessage || error?.message || '';
    const K = MessagesError[message as keyof typeof MessagesError];
    if (K) {
      message = t(K);
    }
    return message || t(MessagesError.somethingWentWrong);
  };
};

const getResponseData = async (rawRes: any) => {
  const res = isFunction(rawRes) ? await rawRes() : await rawRes;
  const data = res?._data || res?.data;
  if (data && Object.keys(data).length !== 1) {
    return data;
  }
  return data?.data || data || res;
};

const handlerRequestOptions: HandlerOptions = {
  getData: getResponseData,
  isRedirectOnNotFound: false,
  showServerError: false,
  toast: {
    error: '',
    success: ''
  }
};
const useHandleRequest = (initOptions: Partial<HandlerOptions> = {}) => {
  const toast = useToast();
  const { routerReplace } = useRouterLocale();
  const apiRoutes = useApiRoutes();
  const getMessageError = useGetMessageErrorByResponse();

  let retry = false;
  const handler = async <D = any>(action: Action<D>, options: Partial<HandlerOptions> = {}) => {
    const {
      getData,
      toast: toastOpts,
      isRedirectOnNotFound,
      showServerError,
      redirectPathForbidden
    } = {
      ...handlerRequestOptions,
      ...initOptions,
      ...options
    };

    try {
      const response = isFunction(action) ? await action() : await action;
      if (IS_BROWSER && toastOpts?.success) {
        toast.success(toastOpts.success);
      }

      return {
        isError: false,
        data: await getData(response),
        response
      };
    } catch (error: any) {
      if (!retry && error.response?.status === 419) {
        retry = true;
        const resCsrf = await handler(apiRoutes.auth.getCsrf);
        if (!resCsrf.isError && isFunction(action)) {
          const retryRes = (await handler<D>(action)) as {
            isError: boolean;
            data: D;
            response: any;
          };
          retry = false;
          return retryRes;
        }
      }

      if (IS_BROWSER && !isAbortError(error) && (toastOpts?.error || showServerError)) {
        const message = toastOpts.error ? toastOpts.error : getMessageError(error);
        if (message) {
          toast.error(message);
        }
      }
      const status = error?.response?.status;
      if (isRedirectOnNotFound && status === 404) {
        showError({ statusCode: 404, statusMessage: 'Page Not Found' });
      }

      if (redirectPathForbidden && status === 403) {
        routerReplace(redirectPathForbidden as any);
      }

      return {
        isError: true,
        data: await getData(error),
        response: error
      };
    }
  };

  return handler;
};

const handleRequestClientOptions = {
  disableIsLoading: true,
  initialState: {
    loading: false,
    data: null as any,
    error: null as any
  },
  handlerOptions: handlerRequestOptions as Partial<HandlerOptions>
};
const useHandlerErrorAndLoadingClient = <D = any>(initOptions?: PartialDeep<typeof handleRequestClientOptions>) => {
  const options = useMerge(useCloneDeep(handleRequestClientOptions), initOptions);

  const state = reactive<{ loading: boolean; data: D; error: any }>(options.initialState);
  const handleRequest = useHandleRequest(options.handlerOptions);
  const handler = async <D = any>(action: Action<D>, handlerOptions: Partial<HandlerOptions> = {}) => {
    if (options.disableIsLoading && state.loading) {
      return {
        isError: true,
        data: state.data,
        response: null
      };
    }
    state.loading = true;
    state.data = options.initialState.data;
    state.error = options.initialState.error;
    const res = await handleRequest(action, handlerOptions);
    state.loading = false;
    if (res.isError) {
      state.error = res.data;
    } else {
      state.data = res.data;
    }
    return res;
  };

  return {
    ...toRefs(state),
    handler
  };
};

export { useGetMessageErrorByResponse, useHandleRequest, useHandlerErrorAndLoadingClient, getResponseData };
