<template>
  <svg
    width="200"
    height="86"
    viewBox="0 0 200 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M136.899 27.436C131.644 11.9789 116.161 3.21986 109.076 0.772461H64.6367C74.6838 13.5246 93.2323 20.0939 99.8015 22.7989C106.371 25.5039 112.554 28.2088 118.737 38.2559C115.336 35.4737 110.364 36.839 108.303 37.8695C112.683 38.2559 121.983 41.5792 124.147 51.7809C131.103 79.2176 145.014 85.3996 155.061 85.3996C165.108 85.3996 170.518 78.4446 178.633 66.0786C185.125 56.1861 195.249 53.4554 199.5 53.3266C194.605 52.0385 182.343 51.1626 172.45 57.9637C160.085 66.4651 161.244 70.7158 155.061 70.3293C150.115 70.0202 149.909 64.2753 150.424 61.4415C150.037 61.5703 149.11 62.5235 148.492 65.3058C145.787 62.6008 143.468 46.7573 136.899 27.436Z"
      fill="#B59E7C"
    />
    <path
      d="M132.262 26.6634C127.006 11.2064 111.524 2.4474 104.439 0H60C70.0471 12.7521 88.5956 19.3213 95.165 22.0263C101.734 24.7313 107.917 27.4363 114.1 37.4834C110.699 34.7011 105.727 36.0665 103.666 37.097C108.046 37.4834 117.346 40.8066 119.51 51.0083C126.465 78.445 140.377 84.627 150.424 84.627C160.471 84.627 165.881 77.6717 173.996 65.3061C180.488 55.4136 190.612 52.6828 194.863 52.554C189.968 51.2659 177.706 50.39 167.813 57.1911C155.447 65.6925 156.607 69.9432 150.424 69.5568C145.478 69.2476 145.271 63.5028 145.787 60.669C145.4 60.7978 144.473 61.751 143.855 64.5332C141.15 61.8283 138.831 45.9848 132.262 26.6634Z"
      fill="#122228"
    />
    <path
      d="M62.6011 27.436C67.8565 11.9789 83.3393 3.21986 90.4238 0.772461H134.863C124.816 13.5246 106.267 20.0939 99.6981 22.7989C93.1288 25.5039 86.946 28.2088 80.7632 38.2559C84.1637 35.4737 89.1357 36.839 91.1967 37.8695C86.8172 38.2559 77.5172 41.5792 75.3532 51.7809C68.3975 79.2176 54.4862 85.3996 44.4391 85.3996C34.392 85.3996 28.982 78.4446 20.867 66.0786C14.3751 56.1861 4.25069 53.4554 0 53.3266C4.89479 52.0385 17.1573 51.1626 27.0499 57.9637C39.4155 66.4651 38.2562 70.7158 44.4391 70.3293C49.3853 70.0202 49.5914 64.2753 49.0762 61.4415C49.4626 61.5703 50.39 62.5235 51.0083 65.3058C53.7133 62.6008 56.0319 46.7573 62.6011 27.436Z"
      fill="#B59E7C"
    />
    <path
      d="M67.2378 26.6634C72.4932 11.2064 87.9761 2.4474 95.0606 0H139.5C129.453 12.7521 110.904 19.3213 104.335 22.0263C97.7656 24.7313 91.5827 27.4363 85.3999 37.4834C88.8004 34.7011 93.7726 36.0665 95.8336 37.097C91.4539 37.4834 82.1539 40.8066 79.9899 51.0083C73.0342 78.445 59.1229 84.627 49.0758 84.627C39.0287 84.627 33.6187 77.6717 25.5038 65.3061C19.0118 55.4136 8.88742 52.6828 4.63672 52.554C9.53152 51.2659 21.7941 50.39 31.6866 57.1911C44.0522 65.6925 42.8929 69.9432 49.0758 69.5568C54.022 69.2476 54.2281 63.5028 53.7129 60.669C54.0993 60.7978 55.0267 61.751 55.645 64.5332C58.35 61.8283 60.6686 45.9848 67.2378 26.6634Z"
      fill="#122228"
    />
  </svg>
</template>
