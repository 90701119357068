<template>
  <component
    :is="tag"
    :class="classes"
    v-bind="$attrs"
  >
    <slot>
      <ui-icon
        v-if="icon"
        :name="icon"
        :class="iconClasses"
      />
      <ui-typography
        v-if="text"
        variant="caption"
        class="inline-flex"
      >
        {{ text }}
      </ui-typography>
    </slot>
  </component>
</template>

<script lang="ts" setup>
  import { cva, type VariantProps } from 'class-variance-authority';
  const chipVariants = cva(
    'inline-flex items-center justify-center gap-0.5 text-ellipsis overflow-hidden whitespace-nowrap font-medium',
    {
      variants: {
        variant: {
          primary: 'bg-primary text-white',
          secondary: 'bg-purple text-white',
          'secondary-outline': 'bg-action-secondary text-action border border-action',
          warning: 'bg-warning text-white',
          'warning-light': 'bg-warning-light text-warning',
          gray: 'bg-gray text-body-text',
          info: 'bg-info text-white',
          success: 'bg-success text-white',
          custom: ''
        },
        size: {
          xs: 'p-0.5',
          sm: 'py-1.5 px-2',
          base: 'py-2 px-4',
          custom: ''
        },
        rounded: {
          xs: 'rounded-xs',
          sm: 'rounded-sm',
          md: 'rounded',
          lg: 'rounded-lg'
        }
      }
    }
  );
  export type ChipVariantsProps = VariantProps<typeof chipVariants>;
  type Props = {
    text?: string | number;
    tag?: keyof HTMLElementTagNameMap;
    size?: ChipVariantsProps['size'];
    variant?: ChipVariantsProps['variant'];
    rounded?: ChipVariantsProps['rounded'];
    icon?: IconName;
    iconClasses?: ClassesType;
  };
  defineOptions({
    inheritAttrs: false
  });
  const props = withDefaults(defineProps<Props>(), {
    tag: 'span',
    size: 'base',
    variant: 'primary',
    rounded: 'lg'
  });
  const attrs = useAttrs();
  const classes = computed(() => cn(chipVariants(props), attrs.class || ''));
</script>
