<template>
  <div class="flex flex-col h-full max-h-dvh">
    <NuxtPwaManifest />
    <nuxt-loading-indicator
      v-if="!appStore.isDisconnect"
      color="#B59E7C"
    />
    <NuxtLayout>
      <nuxt-page v-if="!appStore.showOffline" />
      <div
        v-else
        class="flex flex-col w-full"
      >
        <common-offline />
      </div>
    </NuxtLayout>
    <ModalsContainer />
    <ui-btn-stop-impersonate />
  </div>
</template>

<script setup lang="ts">
  import { ModalsContainer } from 'vue-final-modal';
  subscribeUserNotificationChannel();
  withOgImage();
  const router = useRouter();
  const appStore = useAppStore();
  useConnectionManager();
  tryOnScopeDispose(
    router.beforeEach((_to, _form, next) => {
      appStore.setShowOffline(appStore.isDisconnect);
      next();
    })
  );
  useWatchDisconnect({
    online() {
      appStore.setShowOffline(false);
    }
  });
  if (IS_BROWSER && navigator && !navigator.onLine) {
    appStore.setShowOffline(true);
  }
</script>
