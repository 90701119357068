
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(updateLocale)

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(relativeTime)



// defaultLocale: "en"


dayjs.updateLocale("en")
dayjs.locale('en')


export default dayjs
