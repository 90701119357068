import { FetchError } from 'ofetch';
import { defineNuxtPlugin } from '#app';
import { createHttpClient } from './httpFactory';
import { useSanctumAuth } from './composables/useSanctumAuth';
import { useSanctumConfig } from './composables/useSanctumConfig';

function handleIdentityLoadError(error: Error) {
  if (error instanceof FetchError && error.response && [401, 419].includes(error.response.status)) {
    // unauthenticated user, unable to get information
  } else {
    console.error('Unable to load user identity', error);
  }
}

export default defineNuxtPlugin(async nuxtApp => {
  const { user, parseUserData } = useSanctumAuth();
  const client = createHttpClient();
  const options = useSanctumConfig();

  if (user.value === null && nuxtApp.ssrContext) {
    try {
      user.value = parseUserData(await client(options.endpoints.user));
    } catch (error) {
      handleIdentityLoadError(error as Error);
    }
  }

  return {
    provide: {
      sanctumClient: client
    }
  };
});
