<template>
  <div class="flex items-center justify-between gap-3">
    <ui-typography>{{ t('Could not connect to server.') }}</ui-typography>
    <div class="shrink-0">
      <ui-button
        size="xs"
        variant="secondary"
        icon-left="refresh"
        icon-class-left="text-base mr-0.5"
        class="shrink-0"
        @click="onRefresh"
      >
        {{ t('Refresh') }}
      </ui-button>
    </div>
  </div>
</template>

<script setup lang="ts">
  const onRefresh = () => {
    window.location.reload();
  };
  const { t } = useNuxtApp().$i18n;
</script>
