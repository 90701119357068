const useAppStore = defineStore('app-store', () => {
  const isDisconnect = ref(false);
  const showOffline = ref(false);
  const setDisconnection = (value: boolean) => {
    isDisconnect.value = value;
  };

  const setShowOffline = (value: boolean) => {
    showOffline.value = value;
  };

  return {
    isDisconnect,
    showOffline,
    setDisconnection,
    setShowOffline
  };
});

export default useAppStore;
