<template>
  <div class="typography">
    <div class="flex flex-wrap justify-between gap-4 items-center">
      <h1 class="">REFUND POLICY</h1>
      <p>Last updated July 29, 2024</p>
    </div>
    <ol>
      <li>
        Generally, all charges for purchases are nonrefundable, and there are no refunds or credits for partially used
        periods. We may make an exception if a refund for a subscription offering is requested within fourteen days of
        the transaction date, or if the laws applicable in your jurisdiction provide for refunds.
      </li>
      <li>
        For subscribers residing in the EU or European Economic Area - in accordance with local law, you are entitled to
        a full refund without stating the reason during the 14 days after the subscription begins. Please note that this
        14-day period commences when the subscription starts.
      </li>
      <li>
        For subscribers and purchasers of Virtual Items residing in the Republic of Korea &ndash; in accordance with
        local law, you are entitled to a full refund of your subscription and/or unused Virtual Items during the 7 days
        following the purchase. Please note that this 7-day period commences upon the purchase.
      </li>
    </ol>
    <p class="font-italic">
      Except as noted above for members resident in the Republic of Korea, purchases of Virtual Items are
      <span class="font-bold">FINAL AND NON-REFUNDABLE</span>
    </p>
    <ol start="4">
      <li>
        If you use your right of cancellation (except for purchases made through your Apple ID, which Apple controls),
        we will refund (or ask Google to refund) all payments received from you, without undue delay and in any case
        within 14 days of the date when we received notice of your decision to cancel the Agreement. We shall make such
        refund using the same means of payment as used by you in the initial transaction. In any case, no fees will be
        charged to you as a result of the refund.
      </li>
      <li>
        If you made a purchase through a payment platform not listed above, please request a refund directly from the
        third-party merchant through which you made your purchase.
      </li>
      <li>
        You cannot cancel an order for delivery of digital content that is not delivered on a physical medium if order
        processing has begun with your explicit prior consent and acknowledgement that you will thereby lose your right
        of cancellation. This applies, e.g., to purchases of Virtual Items.
      </li>
    </ol>
    <p class="font-italic">
      That means that such purchases are
      <span class="font-bold">FINAL AND NON-REFUNDABLE.</span>
    </p>
    <h2>PRICING</h2>
    <p>
      <span class="font-bold">SDSV&amp;Meeting</span>
      operates a global business, and our pricing varies by a number of factors. We frequently offer promotional rates -
      which can vary based on region, length of subscription, bundle size and more. We also regularly test new features
      and payment options.
    </p>
  </div>
</template>
<script setup lang="ts"></script>
