<template>
  <div class="typography">
    <div class="flex flex-wrap justify-between gap-4 items-center">
      <h1 class="">PRIVACY POLICY</h1>
      <p class="text-right">Last updated July 29, 2024</p>
    </div>
    <p>
      This privacy notice for SDSV&Meeting sp. z o.o. (doing business as SDSV&Meeting) ( "
      <span class="font-bold">we,</span>
      " "
      <span class="font-bold">us,</span>
      " or "
      <span class="font-bold">our</span>
      " ), describes how and why we might collect, store, use, and/or share ("
      <span class="font-bold">process</span>
      ") your information when you use our services ( "
      <span class="font-bold">Services</span>
      "), such as when you:
    </p>
    <ul>
      <li>Visit our website at sdsvandmeeting.com, or any website of ours that links to this privacy notice</li>
      <li>Engage with us in other related ways, including any sales, marketing, or events</li>
    </ul>

    <p>
      Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If
      you do not agree with our policies and practices, please do not use our Services. If you still have any questions
      or concerns, please contact us at support@sdsvandmeeting.com.
    </p>
    <h2>SUMMARY OF KEY POINTS</h2>
    <p class="font-italic">
      This summary provides key points from our privacy notice, but you can find out more details about any of these
      topics by clicking the link following each key point or by using our table of contents below to find the section
      you are looking for.
    </p>
    <p>
      <span class="font-bold">What personal information do we process?</span>
      When you visit, use, or navigate our Services, we may process personal information depending on how you interact
      with us and the Services, the choices you make, and the products and features you use. Learn more about personal
      information you disclose to us.
    </p>
    <p>
      <span class="font-bold">Do we process any sensitive personal information?</span>
      We do not process sensitive personal information.
    </p>
    <p>
      <span class="font-bold">Do we collect any information from third parties?</span>
      We may collect information from public databases, marketing partners, social media platforms, and other outside
      sources. Learn more about information collected from other sources.
    </p>
    <p>
      <span class="font-bold">How do we process your information?</span>
      We process your information to provide, improve, and administer our Services, communicate with you, for security
      and fraud prevention, and to comply with law. We may also process your information for other purposes with your
      consent. We process your information only when we have a valid legal reason to do so. Learn more about how we
      process your information.
    </p>
    <p>
      <span class="font-bold">
        In what situations and with which types of parties do we share personal information?
      </span>
      We may share information in specific situations and with specific categories of third parties. Learn more about
      when and with whom we share your personal information.
    </p>
    <p>
      <span class="font-bold">How do we keep your information safe?</span>
      We have organizational and technical processes and procedures in place to protect your personal information.
      However, no electronic transmission over the internet or information storage technology can be guaranteed to be
      100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties
      will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn
      more about how we keep your information safe.
    </p>
    <p>
      <span class="font-bold">What are your rights?</span>
      Depending on where you are located geographically, the applicable privacy law may mean you have certain rights
      regarding your personal information. Learn more about your privacy rights.
    </p>
    <p><span class="font-bold">How do you exercise your rights?</span></p>
    <p>
      <span class="font-bold">
        The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We
        will consider and act upon any request in accordance with applicable data protection laws.
      </span>
    </p>
    <p>
      <span class="font-bold">Want to learn more about what we do with any information we collect?</span>
      Review the privacy notice in full.
    </p>

    <!-- TABLE OF CONTENTS -->
    <h2>TABLE OF CONTENTS</h2>
    <ol>
      <li>
        <a href="#privacy-policy-1">WHAT INFORMATION DO WE COLLECT?</a>
      </li>
      <li>
        <a href="#privacy-policy-2">HOW DO WE PROCESS YOUR INFORMATION?</a>
      </li>
      <li>
        <a href="#privacy-policy-3">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
      </li>
      <li>
        <a href="#privacy-policy-4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
      </li>
      <li>
        <a href="#privacy-policy-5">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
      </li>
      <li>
        <a href="#privacy-policy-6">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
      </li>
      <li>
        <a href="#privacy-policy-7">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
      </li>
      <li>
        <a href="#privacy-policy-8">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
      </li>
      <li>
        <a href="#privacy-policy-9">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
      </li>
      <li>
        <a href="#privacy-policy-10">DO WE COLLECT INFORMATION FROM MINORS?</a>
      </li>
      <li>
        <a href="#privacy-policy-11">WHAT ARE YOUR PRIVACY RIGHTS?</a>
      </li>
      <li>
        <a href="#privacy-policy-12">
          CONTROLS FOR DO-NOT-TRACK FEATURES 13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </a>
      </li>
      <li>
        <a href="#privacy-policy-13">DO WE MAKE UPDATES TO THIS NOTICE?</a>
      </li>
      <li>
        <a href="#privacy-policy-14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
      </li>
      <li>
        <a href="#privacy-policy-15">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
      </li>
    </ol>

    <!-- 1. WHAT INFORMATION DO WE COLLECT? -->
    <h2 id="privacy-policy-1">1. WHAT INFORMATION DO WE COLLECT?</h2>
    <h3>Personal information you disclose to us</h3>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We collect personal information that you provide to us.
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when you register on the Services, express an
      interest in obtaining information about us or our products and Services, when you participate in activities on the
      Services, or otherwise when you contact us. Personal Information Provided by You. The personal information that we
      collect depends on the context of your interactions with us and the Services, the choices you make, and the
      products and features you use. The personal information we collect may include the following:
    </p>
    <ul>
      <li>Names</li>
      <li>phone numbers</li>
      <li>email addresses</li>
      <li>usernames</li>
      <li>passwords</li>
      <li>contact preferences</li>
      <li>
        contact or authentication data example, we may use GPS and other technologies to collect geolocation data that
        tells us your current location (based on your IP address). You can opt out of allowing us to collect this
        information either by refusing access to the information or by disabling your Location setting on your device.
        However, if you choose to opt out, you may not be able to use certain aspects of the Services.
      </li>
    </ul>
    <h3>Google API</h3>
    <p>
      Our use of information received from
      <a href="https://developers.google.com/terms/api-services-user-data-policy">
        Google APIs will adhere to Google API Services User Data Policy
      </a>
      ,including the
      <a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use">
        Limited Use requirements.
      </a>
    </p>
    <h3>Information collected from other sources</h3>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We may collect limited data from public databases, marketing partners, social media platforms, and other outside
      sources.
    </p>
    <p>
      In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records,
      we may obtain information about you from other sources, such as public databases, joint marketing partners,
      affiliate programs, data providers, social media platforms, and from other third parties. This information
      includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data),
      Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of
      targeted advertising and event promotion.
    </p>
    <p>
      If you interact with us on a social media platform using your social media account (e.g., Facebook or X), we
      receive personal information about you from such platforms such as your name, email address, and gender. Any
      personal information that we collect from your social media account depends on your social media account’s privacy
      settings. Please note that their own use of your information is not governed by this privacy notice.
    </p>

    <!-- 2. HOW DO WE PROCESS YOUR INFORMATION? -->
    <h2 id="privacy-policy-2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We process your information to provide, improve, and administer our Services, communicate with you, for security
      and fraud prevention, and to comply with law. We may also process your information for other purposes with your
      consent.
    </p>
    <p class="font-bold">
      We process your personal information for a variety of reasons, depending on how you interact with our Services,
      including:
    </p>
    <ul>
      <li>
        To facilitate account creation and authentication and otherwise manage user accounts. We may process your
        information so you can create and log in to your account, as well as keep your account in working order.
      </li>
      <li>
        To deliver and facilitate delivery of services to the user. We may process your information to provide you with
        the requested service.
      </li>
      <li>
        To respond to user inquiries/offer support to users. We may process your information to respond to your
        inquiries and solve any potential issues you might have with the requested service.
      </li>
      <li>
        To send administrative information to you. We may process your information to send you details about our
        products and services, changes to our terms and policies, and other similar information.
      </li>
      <li>
        To fulfill and manage your orders. We may process your information to fulfill and manage your orders, payments,
        returns, and exchanges made through the Services.
      </li>
      <li>
        To enable user-to-user communications. We may process your information if you choose to use any of our offerings
        that allow for communication with another user.
      </li>
      <li>
        To request feedback. We may process your information when necessary to request feedback and to contact you about
        your use of our Services.
      </li>
      <li>
        To send you marketing and promotional communications. We may process the personal information you send to us for
        our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our
        marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.
      </li>
      <li>
        To deliver targeted advertising to you. We may process your information to develop and display personalized
        content and advertising tailored to your interests, location, and more.
      </li>
      <li>
        To protect our Services. We may process your information as part of our efforts to keep our Services safe and
        secure, including fraud monitoring and prevention.
      </li>
      <li>
        To identify usage trends. We may process information about how you use our Services to better understand how
        they are being used so we can improve them.
      </li>
      <li>
        To determine the effectiveness of our marketing and promotional campaigns. We may process your information to
        better understand how to provide marketing and promotional campaigns that are most relevant to you.
      </li>
      <li>
        To save or protect an individual's vital interest. We may process your information when necessary to save or
        protect an individual's vital interest, such as to prevent harm.
      </li>
    </ul>

    <!-- 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? -->
    <h2 id="privacy-policy-3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.,
      legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with
      services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our
      legitimate business interests.
    </p>
    <h3 class="underline">If you are located in the EU or UK, this section applies to you.</h3>
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on
      in order to process your personal information. As such, we may rely on the following legal bases to process your
      personal information:
    </p>
    <ul>
      <li>
        <span class="font-bold">Consent.</span>
        We may process your information if you have given us permission (i.e., consent) to use your personal information
        for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
      </li>
      <li>
        <span class="font-bold">Performance of a Contract.</span>
        We may process your personal information when we believe it is necessary to fulfill our contractual obligations
        to you, including providing our Services or at your request prior to entering into a contract with you.
      </li>
      <li>
        <span class="font-bold">Performance of a Contract.</span>
        We may process your personal information when we believe it is necessary to fulfill our contractual obligations
        to you, including providing our Services or at your request prior to entering into a contract with you.
      </li>
      <li>
        <span class="font-bold">
          Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve
          legitimate business interests and those interests do not outweigh your interests and fundamental rights and
          freedoms. For example, we may process your personal information for some of the purposes described in order
          to:
        </span>
        <ul>
          <li>Send users information about special offers and discounts on our products and services</li>
          <li>Develop and display personalized and relevant advertising content for our users</li>
          <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
          <li>Support our marketing activities</li>
          <li>Diagnose problems and/or prevent fraudulent activities</li>
          <li>Understand how our users use our products and services so we can improve user experience</li>
        </ul>
      </li>
      <li>
        <span class="font-bold">Legal Obligations.</span>
        We may process your information where we believe it is necessary for compliance with our legal obligations, such
        as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or
        disclose your information as evidence in litigation in which we are involved.
      </li>
      <li>
        <span class="font-bold">Vital Interests.</span>
        We may process your information where we believe it is necessary to protect your vital interests or the vital
        interests of a third party, such as situations involving potential threats to the safety of any person.
      </li>
    </ul>

    <h3 class="underline">If you are located in Canada, this section applies to you.</h3>
    <p>
      We may process your information if you have given us specific permission (i.e., express consent) to use your
      personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied
      consent). You can withdraw your consent at any time.
    </p>
    <p>
      In some exceptional cases, we may be legally permitted under applicable law to process your information without
      your consent, including, for example:
    </p>

    <ul>
      <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
      <li>For investigations and fraud detection and prevention</li>
      <li>For business transactions provided certain conditions are met</li>
      <li>
        If it is contained in a witness statement and the collection is necessary to assess, process, or settle an
        insurance claim
      </li>
      <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
      <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
      <li>
        If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy
        of the information and the collection is reasonable for purposes related to investigating a breach of an
        agreement or a contravention of the laws of Canada or a province
      </li>
      <li>
        If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the
        production of records
      </li>
      <li>
        If it was produced by an individual in the course of their employment, business, or profession and the
        collection is consistent with the purposes for which the information was produced
      </li>
      <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
      <li>If the information is publicly available and is specified by the regulations</li>
    </ul>

    <!-- 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? -->
    <h2 id="privacy-policy-4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We may share information in specific situations described in this section and/or with the following categories of
      third parties.
    </p>
    <p>
      <span class="font-bold">Vendors, Consultants, and Other Third-Party Service Providers.</span>
      We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who
      perform services for us or on our behalf and require access to such information to do that work. We have contracts
      in place with our third parties, which are designed to help safeguard your personal information. This means that
      they cannot do anything with your personal information unless we have instructed them to do it. They will also not
      share your personal information with any organization apart from us. They also commit to protect the data they
      hold on our behalf and to retain it for the period we instruct.
    </p>
    <p>Ad Networks</p>
    <ul>
      <li>Affiliate Marketing Programs</li>
      <li>Al Platforms</li>
      <li>Data Analytics Services</li>
      <li>Payment Processors</li>
      <li>Retargeting Platforms</li>
      <li>Sales &amp; Marketing Tools</li>
      <li>Social Networks</li>
      <li>User Account Registration &amp; Authentication Services</li>
    </ul>

    <p class="demoTitle">We also may need to share your personal information in the following situations:</p>
    <ul>
      <li>
        <span class="font-bold">Business Transfers</span>
        We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
        company assets, financing, or acquisition of all or a portion of our business to another company.
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">When we use Google Maps Platform APIs</span>
        We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We
        obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at
        the contact details provided at the end of this document.
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Affiliates</span>
        We may share your information with our affiliates, in which case we will require those affiliates to honor this
        privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other
        companies that we control or that are under common control with us.
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Business Partners</span>
        We may share your information with our business partners to offer you certain products, services, or promotions.
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Other Users</span>
        . When you share personal information (for example, by posting comments, contributions, or other content to the
        Services) or otherwise interact with public areas of the Services, such personal information may be viewed by
        all users and may be publicly made available outside the Services in perpetuity. If you interact with other
        users of our Services and register for our Services through a social network (such as Facebook), your contacts
        on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other
        users will be able to view descriptions of your activity, communicate with you within our Services, and view
        your profile.
      </li>
    </ul>

    <!-- 5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?  -->
    <h2 id="privacy-policy-5">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We are not responsible for the safety of any information that you share with third parties that we may link to or
      who advertise on our Services, but are not affiliated with, our Services.
    </p>
    <p>
      The Services may link to third-party websites, online services, or mobile applications and/or contain
      advertisements from third parties that are not affiliated with us and which may link to other websites, services,
      or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be
      liable for any loss or damage caused by the use of such third-party websites, services, or applications. The
      inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We
      cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third
      parties is not covered by this privacy notice. We are not responsible for the content or privacy and security
      practices and policies of any third parties, including other websites, services, or applications that may be
      linked to or from the Services. You should review the policies of such third parties and contact them directly to
      respond to your questions.
    </p>

    <!-- 6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? -->
    <h2 id="privacy-policy-6">6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We may use cookies and other tracking technologies to collect and store your information.
    </p>
    <p>
      We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you
      interact with our Services. Some online tracking technologies help us maintain the security of our Services and
      your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
    </p>
    <p>
      We also permit third parties and service providers to use online tracking technologies on our Services for
      analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your
      interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third
      parties and service providers use their technology to provide advertising about products and services tailored to
      your interests which may appear either on our Services or on other websites.
    </p>
    <p>
      To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted
      advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online
      tracking technologies by submitting a request as described below under section "DO UNITED STATES RESIDENTS HAVE
      SPECIFIC PRIVACY RIGHTS?"
    </p>
    <p>
      Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
      Cookie Notice.
    </p>

    <h3>Google Analytics</h3>
    <p>
      We may share your information with Google Analytics to track and analyze the use of the Services. The Google
      Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting,
      Remarketing with Google Analytics and Google Display Network Impressions Reporting. To opt out of being tracked by
      Google Analytics across the Services, visit
      <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
      You can opt out of Google Analytics Advertising Features through
      <a href="https://myadcenter.google.com/home?sasb=true&amp;ref=ad-settings">Ads Settings</a>
      and Ad Settings for mobile apps. Other opt out means include
      <a href="http://optout.networkadvertising.org">http://optout.networkadvertising.org</a>
      and
      <a href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>
      For more information on the privacy practices of Google, please visit the
      <a href="https://policies.google.com/privacy">Google Privacy Terms page</a>
    </p>

    <!-- 7. HOW DO WE HANDLE YOUR SOCIAL LOGINS? -->
    <h2 id="privacy-policy-7">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      If you choose to register or log in to our Services using a social media account, we may have access to certain
      information about you.
    </p>
    <p>
      Our Services offer you the ability to register and log in using your third-party social media account details
      (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about
      you from your social media provider. The profile information we receive may vary depending on the social media
      provider concerned, but will often include your name, email address, friends list, and profile picture, as well as
      other information you choose to make public on such a social media platform.
    </p>
    <p>
      We will use the information we receive only for the purposes that are described in this privacy notice or that are
      otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible
      for, other uses of your personal information by your third-party social media provider. We recommend that you
      review their privacy notice to understand how they collect, use, and share your personal information, and how you
      can set your privacy preferences on their sites and apps.
    </p>

    <!-- 8. HOW LONG DO WE KEEP YOUR INFORMATION? -->
    <h2 id="privacy-policy-8">8. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless
      otherwise required by law.
    </p>

    <p>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this
      privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
      other legal requirements). No purpose in this notice will require us keeping your personal information for longer
      than three (3) months past the termination of the user's account.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or
      anonymize such information, or, if this is not possible (for example, because your personal information has been
      stored in backup archives), then we will securely store your personal information and isolate it from any further
      processing until deletion is possible.
    </p>

    <!-- 9. HOW DO WE KEEP YOUR INFORMATION SAFE? -->
    <h2 id="privacy-policy-9">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We aim to protect your personal information through a system of organizational and technical security measures.
    </p>
    <p>
      We have implemented appropriate and reasonable technical and organizational security measures designed to protect
      the security of any personal information we process. However, despite our safeguards and efforts to secure your
      information, no electronic transmission over the Internet or information storage technology can be guaranteed to
      be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
      parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information, transmission of personal information to and
      from our Services is at your own risk. You should only access the Services within a secure environment.
    </p>

    <!-- 10. DO WE COLLECT INFORMATION FROM MINORS?  -->
    <h2 id="privacy-policy-10">10. DO WE COLLECT INFORMATION FROM MINORS?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      We do not knowingly collect data from or market to children under 18 years of age.
    </p>
    <p>
      We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly
      sell such personal information. By using the Services, you represent that you are at least 18 or that you are the
      parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that
      personal information from users less than 18 years of age has been collected, we will deactivate the account and
      take reasonable measures to promptly delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      <a href="mailto:support@sdsvandmeeting.com">support@sdsvandmeeting.com</a>
    </p>

    <!-- 11. WHAT ARE YOUR PRIVACY RIGHTS? -->
    <h2 id="privacy-policy-11">11. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA),
      United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over
      your personal information. You may review, change, or terminate your account at any time, depending on your
      country, province, or state of residence.
    </p>
    <p>
      In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data
      protection laws. These may include the right (i) to request access and obtain a copy of your personal information,
      (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if
      applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances,
      you may also have the right to object to the processing of your personal information. You can make such a request
      by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
      below.
    </p>
    <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
    <p>
      If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you
      also have the right to complain to your
      <a href="https://ec.europa.eu/newsroom/article29/items/612080/en">Member State data protection authority</a>
      or
      <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
        UK data protection authority
      </a>
    </p>
    <p>
      If you are located in Switzerland, you may contact the
      <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information Commissioner</a>
    </p>
    <p>
      <span class="font-bold">Withdrawing your consent:</span>
      If we are relying on your consent to process your personal information, which may be express and/or implied
      consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw
      your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU
      CONTACT US ABOUT THIS NOTICE?" below or updating your preferences.
    </p>
    <p>
      However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when
      applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
      processing grounds other than consent.
    </p>
    <p>
      <span class="font-bold">Opting out of marketing and promotional communications:</span>
      You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe
      link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, contacting
      support, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
      below. You will then be removed from the marketing lists. However, we may still communicate with you for example,
      to send you service-related messages that are necessary for the administration and use of your account, to respond
      to service requests, or for other non-marketing purposes.
    </p>

    <h3>Account Information</h3>
    <p>
      If you would at any time like to review or change the information in your account or terminate your account, you
      can:
    </p>
    <ul>
      <li>Log in to your account settings and update your user account.</li>
    </ul>
    <p>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases. However, we may retain some information
    </p>
    <p>
      in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms
      and/or comply with applicable legal requirements.
    </p>
    <p>
      <span class="font-bold">Cookies and similar technologies:</span>
      Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser
      to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect
      certain features or services of our Services. You may also
      <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">opt out of interest-based advertising by advertisers</a>
      on our Services.
    </p>
    <p>If you have questions or comments about your privacy rights, you may email us at support@sdsvandmeeting.com.</p>

    <!-- 12. CONTROLS FOR DO-NOT-TRACK FEATURES -->
    <h2 id="#">12. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
    <p>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature
      or setting you can activate to signal your privacy preference not to have data about your online browsing
      activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing
      DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism
      that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice in a revised version of this privacy
      notice.
    </p>
    <p>
      California law requires us to let you know how we respond to web browser DNT signals. Because there currently is
      not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
    </p>

    <!-- 13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? -->
    <h2 id="privacy-policy-13">13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana,
      New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access
      to and receive details about the personal information we maintain about you and how we have processed it, correct
      inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your
      consent to our processing of your personal information. These rights may be limited in some circumstances by
      applicable law. More information is provided below.
    </p>

    <h3>Categories of Personal Information We Collect</h3>
    <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

    <div class="max-w-full overflow-auto">
      <table class="table-auto min-w-[500px]">
        <tbody>
          <tr>
            <td>Category</td>
            <td>Examples</td>
            <td>Collected</td>
          </tr>
          <tr>
            <td>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
              personal identifier, online identifier, Internet Protocol address, email address, and account name
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>B. Personal information as defined in the California Customer Records statute</td>
            <td>Name, contact information, education, employment, employment history, and financial information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>C. Protected classification characteristics under state or federal law</td>
            <td>
              Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic
              data
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>D. Commercial information</td>
            <td>Transaction information, purchase history, financial details, and payment information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
              Browsing history, search history, online behavior, interest data, and interactions with our and other
              websites, applications, systems, and advertisements
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>Audio, electronic, sensory, or similar information</td>
            <td>Images and audio, video or call recordings created in connection with our business activities</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information</td>
            <td>
              Business contact details in order to provide you our Services at a business level or job title, work
              history, and professional qualifications if you apply for a job with us
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>J. Education Information</td>
            <td>Student records and directory information</td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>K. Inferences drawn from collected personal information</td>
            <td>
              Inferences drawn from any of the collected personal information listed above to create a profile or
              summary about, for example, an individual's preferences and characteristics
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>L. Sensitive personal Information</td>
            <td />
            <td>NO</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      We may also collect other personal information outside of these categories through instances where you interact
      with us in person, online, or by phone or mail in the context of:
    </p>
    <ul>
      <li>Receiving help through our customer support channels;</li>
      <li>Participation in customer surveys or contests; and</li>
      <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
    </ul>
    <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
    <ul>
      <li>Category H - As long as the user has an account with us</li>
    </ul>

    <h3>Sources of Personal Information</h3>
    <p>Learn more about the sources of personal information we collect in "WHAT INFORMATION DO WE COLLECT?"</p>
    <h3>How We Use and Share Personal Information</h3>

    <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</p>
    <p>We collect and share your personal information through:</p>
    <ul>
      <li>Targeting cookies/Marketing cookies</li>
      <li>Social media cookies</li>
      <li>Beacons/Pixels/Tags</li>
      <li>Click redirects</li>
      <li>Social media plugins:</li>
    </ul>
    <p>
      We use social media features, such as a "Like" button, and widgets, such as a "Share" button, in our Services.
      Such features may process your Internet Protocol (IP) address and track which page you are visiting on our
      website. We may place a cookie to enable the feature to work correctly. If you are logged in on a certain social
      media platform and you interact with a widget or button belonging to that social media platform, this information
      may be recorded to your profile of such social media platform. To avoid this, you should log out from that social
      media platform before accessing or using the Services. Social media features and widgets may be hosted by a third
      party or hosted directly on our Services. Your interactions with these features are governed by the privacy
      notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of this
      plugin and consequently the transfer of personal information to the corresponding social media service.
    </p>
    <p>We have no control over the essence and extent of these transmitted data or their additional processing.</p>
    <h3>Will your information be shared with anyone else?</h3>
    <p>
      We may disclose your personal information with our service providers pursuant to a written contract between us and
      each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH
      WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
    </p>
    <p>
      We may use your personal information for our own business purposes, such as for undertaking internal research for
      technological development and demonstration. This is not considered to be "selling" of your personal information.
    </p>
    <p>
      We have not sold or shared any personal information to third parties for a business or commercial purpose in the
      preceding twelve (12) months. We have disclosed the following categories of personal information to third parties
      for a business or commercial purpose in the preceding twelve (12) months:
    </p>
    <p>
      The categories of third parties to whom we disclosed personal information for a business or commercial purpose can
      be found under &ldquo;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&rdquo;
    </p>
    <h3>Your Rights</h3>
    <p>
      You have rights under certain US state data protection laws. However, these rights are not absolute, and in
      certain cases, we may decline your request as permitted by law. These rights include:
    </p>
    <ul>
      <li>
        <span class="font-bold">Right to know</span>
        whether or not we are processing your personal data
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to access</span>
        your personal data
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to correct</span>
        inaccuracies in your personal data
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to</span>
        <span class="font-bold">request</span>
        the deletion of your personal data
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to obtain</span>
        a copy of the personal data you previously shared with us
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to non-discrimination</span>
        for exercising your rights
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to opt out</span>
        of the processing of your personal data if it is used for targeted advertising (or sharing as defined under
        California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce
        legal or similarly significant effects ("profiling")
      </li>
    </ul>
    <p>Depending upon the state where you live, you may also have the following rights:</p>
    <ul>
      <li>
        <span class="font-bold">Right to obtain</span>
        a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable
        law, including California's and Delaware's privacy law)
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to obtain</span>
        a list of specific third parties to which we have disclosed personal data (as permitted by applicable law,
        including Oregon's privacy law)
      </li>
      <li>
        <span class="font-bold">Right to limit</span>
        use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy
        law)
      </li>
    </ul>
    <ul>
      <li>
        <span class="font-bold">Right to opt out</span>
        of the collection of sensitive data and personal data collected through the operation of a voice or facial
        recognition feature (as permitted by applicable law, including Florida's privacy law)
      </li>
    </ul>
    <h3>How to Exercise Your Rights</h3>
    <p>
      To exercise these rights, you can contact us by submitting a data subject access request, By contacting support
      through the website or mailing us at support@sdsvandmeeting.com, or by referring to the contact details at the
      bottom of this document.
    </p>
    <p>
      We will honor your opt-out preferences if you enact the
      <a href="https://globalprivacycontrol.org/">Global Privacy Control</a>
      (GPC) opt-out signal on your browser.
    </p>
    <p>
      Under certain US state data protection laws, you can designate an authorized agent to make a request on your
      behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly
      authorized to act on your behalf in accordance with applicable laws.
    </p>
    <h3>Request Verification</h3>
    <p>
      Upon receiving your request, we will need to verify your identity to determine you are the same person about whom
      we have the information in our system. We will only use personal information provided in your request to verify
      your identity or authority to make the request. However, if we cannot verify your identity from the information
      already maintained by us, we may request that you provide additional information for the purposes of verifying
      your identity and for security or fraud-prevention purposes.
    </p>
    <p>
      If you submit the request through an authorized agent, we may need to collect additional information to verify
      your identity before processing your request and the agent will need to provide a written and signed permission
      from you to submit such request on your behalf.
    </p>
    <h3>Appeals</h3>
    <p>
      Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal
      our decision by emailing us at
    </p>
    <p>
      support@sdsvandmeeting.com. We will inform you in writing of any action taken or not taken in response to the
      appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit
      a complaint to your state attorney general.
    </p>
    <h3>California "Shine The Light" Law</h3>
    <p>
      California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
      California residents to request and obtain from us, once a year and free of charge, information about categories
      of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
      addresses of all third parties with which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a request, please submit your request in
      writing to us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
    </p>

    <!-- 14. DO WE MAKE UPDATES TO THIS NOTICE? -->
    <h2 id="privacy-policy-14">14. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

    <p class="font-italic">
      <span class="font-bold">In Short:</span>
      Yes, we will update this notice as necessary to stay compliant with relevant laws.
    </p>

    <p>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised"
      date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you
      either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you
      to review this privacy notice frequently to be informed of how we are protecting your information.
    </p>

    <!-- 15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? -->
    <h2 id="privacy-policy-15">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

    <p>
      If you have questions or comments about this notice, you may email us at
      <span class="font-bold">support@sdsvandmeeting.com</span>
      or contact us by post at:
    </p>
    <p class="font-bold">SDSV&amp;Meeting sp. z o.o.</p>
    <p class="font-bold">ALEJA POWSTANIA WARSZAWSKIEGO 15</p>
    <p class="font-bold">Krakow 31-539</p>
    <p class="font-bold">Poland</p>

    <!-- 16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? -->
    <h2 id="privacy-policy-16">16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
    <p>
      Based on the applicable laws of your country or state of residence in the US, you may have the right to request
      access to the personal information we collect from you, details about how we have processed it, correct
      inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our
      processing of your personal information. These rights may be limited in some circumstances by applicable law. To
      request to review, update, or delete your personal information, please fill out and submit a data subject access
      request.
    </p>
  </div>
</template>
<script setup lang="ts"></script>
