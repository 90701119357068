import type { FetchInstance } from '@refactorjs/ofetch';
import ApiAttachments from '~/services/ApiAttachments';
import ApiAuth from '~/services/ApiAuth';
import ApiInterests from '~/services/ApiInterests';
import ApiMoods from '~/services/ApiMoods';
import ApiNotifications from '~/services/ApiNotifications';
import ApiProfile from '~/services/ApiProfile';
import ApiProfiles from '~/services/ApiProfiles';
import ApiSupport from '~/services/ApiSupport';
import ApiRooms from '~/services/ApiRooms';
import ApiTenor from '~/services/ApiTenor';

const createApiRoutes = (api: FetchInstance) => {
  return {
    auth: ApiAuth(api),
    profile: new ApiProfile(api),
    interests: new ApiInterests(api),
    moods: new ApiMoods(api),
    rooms: new ApiRooms(api),
    attachments: new ApiAttachments(api),
    support: new ApiSupport(api),
    profiles: new ApiProfiles(api),
    notifications: new ApiNotifications(api),
    getSubscriptionPlans: () => api.get('/subscription-plans'),
    getMoodIcons: () => api.get('/mood-icons', { cache: 'force-cache' }),
    tenor: ApiTenor()
  };
};
export default createApiRoutes;
