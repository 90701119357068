import { UiToastOfflineApp } from '#components';

const MAX_OFFLINE_TIME = 2000; // 2s

type OptionsWatchDisconnect = {
  offline?: () => void;
  online?: () => void;
};

const useWatchDisconnect = (options: OptionsWatchDisconnect) => {
  const appStore = useAppStore();
  watchDebounced(
    () => appStore.isDisconnect,
    isDisconnect => {
      if (isDisconnect) {
        options?.offline?.();
      } else {
        options?.online?.();
      }
    },
    { debounce: 300 }
  );
};

const useConnectionManager = () => {
  const toastId = useUniqueId('toast');
  const appStore = useAppStore();
  const toast = useToast();

  useEventListener('offline', () => {
    appStore.setDisconnection(true);
  });
  useEventListener('online', () => {
    appStore.setDisconnection(false);
  });

  const { start, stop } = useTimeoutFn(
    () => {
      if (!appStore.showOffline) {
        toast.info(h(UiToastOfflineApp), {
          autoClose: false,
          toastId
        });
      }
    },
    MAX_OFFLINE_TIME,
    {
      immediate: false
    }
  );
  useWatchDisconnect({
    offline: start,
    online() {
      stop();
      toast.remove(toastId);
    }
  });
};

export { useConnectionManager, useWatchDisconnect };
