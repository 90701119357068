<template>
  <component
    :is="tag"
    :class="variantClass"
    v-bind="{ ...$attrs, class: '' }"
  >
    <slot>
      {{ $t(text || '') }}
    </slot>
  </component>
</template>

<script setup lang="ts">
  type Props = {
    text?: string;
    variant?: KVariantsTypography;
    tag?: keyof HTMLElementTagNameMap;
    noWrap?: boolean;
  };

  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<Props>(), {
    variant: 'body',
    tag: 'p'
  });

  const attrs = useAttrs();

  const variantClass = computed(() =>
    cn(VariantsTypography[props.variant], !props.noWrap && 'break-words', attrs.class as string)
  );
</script>
