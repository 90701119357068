import { useRequestURL } from '#app';
import { withQuery } from 'ufo';
import type { AvailableProviders } from '../../types';
import isAbsolutePath from '../../utils/isAbsolutePath';
import randomString from '../../utils/randomString';
import { useSanctumAuth } from './useSanctumAuth';
import { useSanctumConfig } from './useSanctumConfig';

const useSanctumSocialAuth = () => {
  const requestUrl = useRequestURL();
  const { refreshIdentity } = useSanctumAuth();
  const options = useSanctumConfig();
  const windowReference = ref<Window | null>(null);

  const getCurrentProvider = (provider: AvailableProviders) => options.social?.[provider];

  const getRedirectUri = (provider: AvailableProviders) => {
    const currentProviderOptions = getCurrentProvider(provider);
    if (currentProviderOptions) {
      const path = currentProviderOptions.redirectUri;
      return isAbsolutePath(path) ? requestUrl.origin + path : path;
    }
    return '';
  };
  const getScope = (provider: AvailableProviders) => {
    const currentProviderOptions = getCurrentProvider(provider);
    return Array.isArray(currentProviderOptions?.scope)
      ? currentProviderOptions.scope.join(' ')
      : currentProviderOptions?.scope;
  };

  const clientWindowFeatures = (clientWindowWidth: number, clientWindowHeight: number) => {
    const top = globalThis.top!.outerHeight / 2 + globalThis.top!.screenY - clientWindowHeight / 2;
    const left = globalThis.top!.outerWidth / 2 + globalThis.top!.screenX - clientWindowWidth / 2;
    return `toolbar=no, menubar=no, width=${clientWindowWidth}, height=${clientWindowHeight}, top=${top}, left=${left}`;
  };

  const handelMessage = (cb: (data: any) => void) => (event: MessageEvent) => {
    if (event.data.oauth) {
      cb?.(event.data);
      globalThis.removeEventListener('message', handelMessage(cb));
    }
  };

  const onLogin = (provider: AvailableProviders, cb: (data: any) => void = refreshIdentity) => {
    const currentProviderOptions = getCurrentProvider(provider);

    const authUrl = currentProviderOptions?.endpoints.authorization;
    if (authUrl) {
      const opts = {
        protocol: 'oauth2',
        response_type: currentProviderOptions.responseType,
        client_id: currentProviderOptions.clientId,
        redirect_uri: getRedirectUri(provider),
        scope: getScope(provider),
        // Note: The primary reason for using the state parameter is to mitigate CSRF attacks.
        // https://auth0.com/docs/protocols/oauth2/oauth-state
        state: randomString(10),
        clientWindow: options.clientWindow,
        clientWindowWidth: options.clientWindowWidth,
        clientWindowHeight: options.clientWindowHeight,
        nonce: undefined as any
      };

      if (opts.response_type?.includes('token') || opts.response_type?.includes('id_token')) {
        opts.nonce = randomString(10);
      }

      if (opts.clientWindow) {
        if (!windowReference.value || windowReference.value.closed) {
          const windowFeatures = clientWindowFeatures(opts.clientWindowWidth, opts.clientWindowHeight);
          windowReference.value = globalThis.open('about:blank', 'oauth2-client-window', windowFeatures);
          const messageHandler = handelMessage(cb);

          globalThis.removeEventListener('message', messageHandler);
          globalThis.addEventListener('message', messageHandler);
          const checkPopUpInterval = setInterval(() => {
            try {
              if (windowReference.value?.closed) {
                windowReference.value = null;
                clearInterval(checkPopUpInterval);
                globalThis.removeEventListener('message', messageHandler);
              }
            } catch (e) {
              clearInterval(checkPopUpInterval);
              windowReference.value = null;
            }
          }, 500);
        } else {
          windowReference.value!.focus();
        }
      }

      const url = withQuery(authUrl, opts);
      if (opts.clientWindow) {
        if (windowReference.value) {
          windowReference.value.location = url;
        }
      } else {
        globalThis.location.replace(url);
      }
    }
  };

  return onLogin;
};

export default useSanctumSocialAuth;
