<template>
  <component
    :is="componentData.tag"
    :class="classes"
    :disabled="disabledBtn"
    :aria-disabled="disabledBtn"
    v-bind="componentData.props"
    @click="onClick"
  >
    <span :class="contentClasses">
      <slot name="icon-left">
        <span
          v-if="iconLeft"
          :class="iconWrapClasses"
        >
          <ui-icon
            :name="iconLeft"
            :filled="iconLeftFilled"
            :type="iconLeftType"
            :class="[iconClassLeft]"
          />
        </span>
      </slot>
      <slot>
        <span
          v-if="text"
          class="inline-flex"
        >
          {{ $t(text || '') }}
        </span>
      </slot>
      <slot name="icon-right">
        <span
          v-if="iconRight"
          :class="iconWrapClasses"
        >
          <ui-icon
            :name="iconRight"
            :filled="iconRightFilled"
            :type="iconRightType"
            :class="[iconClassRight]"
          />
        </span>
      </slot>
    </span>
    <span
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <ui-spinner />
    </span>
  </component>
</template>

<script setup lang="ts">
  import { btnSizeIcons, btnVariants, btnVariantsContent, extendVariants } from '@/components/ui/Button/styles';
  const props = withDefaults(defineProps<ButtonProps>(), {
    type: 'button',
    activeClass: 'active',
    size: 'lg',
    exactActiveClass: 'active'
  });

  defineOptions({
    inheritAttrs: false
  });
  const emit = defineEmits(['click']);
  const attrs = useAttrs();
  const NuxtLinkLocale = resolveComponent('NuxtLinkLocale');

  const classes = computed(() =>
    cn(
      btnVariants({
        variant: props.variant,
        size: props.hideSpace ? undefined : props.size,
        rounded: props.rounded,
        underline: props.underline
      }),
      { 'w-full': props.full, active: props.active, 'text-left': props.iconExtend },
      attrs.class as ClassesType
    )
  );

  const contentClasses = computed(() =>
    btnVariantsContent({
      direct: props.direct,
      size: props.size as any,
      loading: props.loading
    })
  );

  const disabledBtn = computed(() => props.loading || props.disabled);
  const isLink = computed(() => props.to || props.href);

  const componentData = computed(() => {
    const { class: _class, ...otherAttrs } = attrs;

    if (props.tag) {
      return {
        tag: props.tag,
        props: otherAttrs
      };
    }
    if (isLink.value) {
      const path = props.to ? props.to : props.href;
      return {
        tag: NuxtLinkLocale,
        props: {
          ...otherAttrs,
          target: props.target,
          external: props.external,
          download: props.download || undefined,
          activeClass: props.activeClass,
          exactActiveClass: props.exactActiveClass,
          to: path || ''
        }
      };
    }
    return {
      tag: 'button',
      props: {
        ...otherAttrs,
        type: props.type
      }
    };
  });

  const iconWrapClasses = computed(() => {
    const defaultClasses = 'flex items-center shrink-0 justify-center ';
    if (props.iconVariant) {
      return cn(defaultClasses, extendVariants({ variant: props.iconVariant }));
    }
    if (props.iconExtend) {
      const sizeClass = (props.size && (btnSizeIcons as any)[props.size]) || btnSizeIcons['sm-icon'];
      return cn('rounded', defaultClasses, sizeClass, extendVariants({ variant: props.variant }));
    }
    return defaultClasses;
  });

  const onClick = (event: MouseEvent) => {
    if (disabledBtn.value) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    emit('click', event);
  };
</script>

<style scoped lang="scss">
  .base-button {
    animation: button-pop 0.25s ease-out;
    transition-property:
      color,
      background-color,
      border-color,
      text-decoration-color,
      fill,
      stroke,
      opacity,
      box-shadow,
      transform,
      filter,
      backdrop-filter,
      -webkit-text-decoration-color,
      -webkit-backdrop-filter;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
</style>
