<template>
  <ui-button-async
    v-if="user && user.impersonating"
    v-tooltip="$t('Stop impersonating')"
    class="fixed z-40 md:right-8 md:bottom-10 right-4 bottom-4"
    rounded="full"
    size="lg-icon"
    icon-left="logout"
    :request="apiRoutes.auth.stopImpersonating"
    @success="onSuccess"
  />
</template>

<script setup lang="ts">
  const { user } = useAuthUser();
  const apiRoutes = useApiRoutes();
  const onSuccess = (data: { redirect: string }) => {
    window.location.href = data.redirect;
  };
</script>
