<template>
  <common-room-chat-message-attachment-info
    :size="source.size"
    :name="source.name"
    class="flex flex-wrap gap-3"
  >
    <div class="flex gap-2">
      <ui-button
        variant="secondary"
        :size="large ? 'md' : 'xs-icon'"
        rounded="sm"
        :text="large ? 'Download' : ''"
        icon-left="download"
        @click="downloadFile"
      />
      <ui-button
        v-if="isAuthor"
        variant="error"
        :size="large ? 'md' : 'xs-icon'"
        rounded="sm"
        :text="large ? 'Delete' : ''"
        icon-left="delete"
        @click="onRemove"
      />
    </div>
  </common-room-chat-message-attachment-info>
</template>

<script setup lang="ts">
  type Props = {
    source: MessageAttachment;
    isAuthor: boolean;
    large?: boolean;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['remove']);

  const chatStore = useChatStore();
  const downloadFile = () => downloadStorageFile(props.source.url, props.source.name);
  const onRemove = async () => {
    if (await chatStore.onRemoveAttachment(props.source.message_id, props.source.id)) {
      emit('remove', props.source.id);
    }
  };
</script>
