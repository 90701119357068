import { DateFormats, type TDateFormats } from '~/globals/DateFormats';

const useSecondToMMSS = (s: MaybeRef<number>) => {
  const dayjs = useDayjs();
  return computed<string>(() => dayjs(0).add(unref(s), 's').format('mm:ss'));
};

const useCalculateAge = () => {
  const dayjs = useDayjs();

  return (rawDate?: MaybeRef<any>) => {
    const prepareData = unref(rawDate);
    const currentDate = dayjs();
    if (prepareData) {
      const date = dayjs(prepareData);
      if (date.isValid()) {
        return currentDate.diff(date, 'years');
      }
      return date;
    }

    return rawDate;
  };
};

const useFormatDate = (value?: MaybeRef<any>, format: TDateFormats = DateFormats.Base, isUnix = false) => {
  const dayjs = useDayjs();
  const { t } = useI18n();
  const formatDate = (rawDate?: MaybeRef<any>, format: TDateFormats = DateFormats.Base, isUnixInner = isUnix) => {
    const prepareData = unref(rawDate);
    if (prepareData) {
      const date = isUnixInner ? dayjs.unix(prepareData) : dayjs(prepareData);
      if (date.isValid()) {
        return date.format(format);
      }
      return date;
    }
  };

  const formatTimestamp = (rawDate?: MaybeRef<string>) => {
    const date = dayjs(unref(rawDate));
    if (date.isValid()) {
      if (date.isSame(dayjs(), 'd')) {
        return date.format(DateFormats.BaseMinuteAndHour);
      }
      if (date.diff(dayjs(), 'y') > 1) {
        return date.format(DateFormats.PrettyMonthAndMinutesAndYear);
      }
      return date.format(DateFormats.PrettyMonthAndMinutes);
    }
    return '';
  };

  const formatGroupDate = (rawDate?: MaybeRef<string>) => {
    const date = dayjs(unref(rawDate));
    if (date.isValid()) {
      if (date.isToday()) {
        return t('Today');
      }
      if (date.diff(dayjs(), 'y') > 1) {
        return date.format(DateFormats.ChatWithOldYear);
      }
      return date.format(DateFormats.ChatWithCurrentYear);
    }
    return '';
  };

  return {
    formatGroupDate,
    formatDate,
    DateFormats,
    formatTimestamp,
    formatsVal: computed(() => formatDate(value, format, isUnix))
  };
};

export { useFormatDate, useCalculateAge, useSecondToMMSS };
