const scrollToEl = (el?: HTMLElement | Element | null, offset: number = -200) => {
  if (el) {
    const { top } = el.getBoundingClientRect();
    const offsetPosition = top + window.scrollY + offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

export { scrollToEl };
