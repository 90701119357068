const VariantsTypography = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body: 'md:text-base text-xs',
  body2: 'md:text-lg text-base',
  lg: 'md:text-2xl text-xs',
  caption: 'md:text-sm text-xs',
  small: 'md:text-xs text-xxs'
} as const;

export { VariantsTypography };
