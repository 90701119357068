import { useModal, type ComponentProps } from 'vue-final-modal';
import type { Component } from 'vue';

const ModalConfirm = defineAsyncComponent(() => import('@/components/common/modals/Confirm.vue'));

const CreateInterestModal = defineAsyncComponent(() => import('@/components/common/modals/CreateInterest.vue'));

const ChooseAvatarModal = defineAsyncComponent(() => import('@/components/common/modals/ChooseAvatar.vue'));

const CropImageModal = defineAsyncComponent(() => import('@/components/common/modals/CropImage.vue'));

const UpdateInterestsModal = defineAsyncComponent(() => import('@/components/common/modals/UpdateInterests.vue'));

const MediaPreviewModal = defineAsyncComponent(() => import('@/components/common/modals/MediaPreview.vue'));
const MediaChatAttachmentPreviewModal = defineAsyncComponent(
  () => import('@/components/common/modals/MediaPreviewChatAttachment.vue')
);

const UpdatePreviewMediaModal = defineAsyncComponent(() => import('@/components/common/modals/UpdatePreviewMedia.vue'));

const UploadMediaModal = defineAsyncComponent(() => import('@/components/common/modals/UploadMedia.vue'));

const AdminPermissionsModal = defineAsyncComponent(() => import('@/components/common/modals/AdminPermissions.vue'));

const CropRoomAvatarModal = defineAsyncComponent(() => import('@/components/common/modals/CropRoomAvatar.vue'));
const ModalAttachFileChat = defineAsyncComponent(() => import('@/components/common/room/chat/ModalAttachFile.vue'));
const CustomMoodModal = defineAsyncComponent(() => import('@/components/common/modals/CustomMood.vue'));

const withModalPatch =
  <T extends Component>(component: T) =>
  (initialAttrs: ComponentProps<T>) => {
    const vfm = useModal({
      component,
      attrs: useCloneDeep(initialAttrs)
    });

    return {
      ...vfm,
      async open(attrs?: ComponentProps<T>) {
        vfm.patchOptions({
          attrs: useMerge(useCloneDeep(initialAttrs), attrs)
        });
        return vfm.open();
      }
    };
  };

const useConfirmModal = withModalPatch<typeof ModalConfirm>(ModalConfirm);
const useCreateInterestModal = withModalPatch<Partial<typeof CreateInterestModal>>(CreateInterestModal);
const useChooseAvatarModal = withModalPatch<Partial<typeof ChooseAvatarModal>>(ChooseAvatarModal);
const useCropImageModal = withModalPatch<Partial<typeof CropImageModal>>(CropImageModal);

const useUpdateInterestsModal = withModalPatch<Partial<typeof UpdateInterestsModal>>(UpdateInterestsModal);

const useMediaPreviewModal = withModalPatch<Partial<typeof MediaPreviewModal>>(MediaPreviewModal);
const useChatAttachmentPreviewModal = withModalPatch<Partial<typeof MediaChatAttachmentPreviewModal>>(
  MediaChatAttachmentPreviewModal
);

const useUpdatePreviewMediaModal = withModalPatch<Partial<typeof UpdatePreviewMediaModal>>(UpdatePreviewMediaModal);

const useUploadMediaModal = withModalPatch<Partial<typeof UploadMediaModal>>(UploadMediaModal);

const useCropRoomAvatarModal = withModalPatch<Partial<typeof CropRoomAvatarModal>>(CropRoomAvatarModal);

const useAdminPermissionsModal = withModalPatch<Partial<typeof AdminPermissionsModal>>(AdminPermissionsModal);
const useModalAttachFileChat = withModalPatch<Partial<typeof ModalAttachFileChat>>(ModalAttachFileChat);
const useModalCustomMoodModal = withModalPatch<Partial<typeof CustomMoodModal>>(CustomMoodModal);

export {
  useConfirmModal,
  useCreateInterestModal,
  useUpdateInterestsModal,
  useMediaPreviewModal,
  useChooseAvatarModal,
  useCropImageModal,
  useUploadMediaModal,
  useUpdatePreviewMediaModal,
  useCropRoomAvatarModal,
  useAdminPermissionsModal,
  useModalAttachFileChat,
  useModalCustomMoodModal,
  useChatAttachmentPreviewModal
};
