import type { FetchInstance } from '@refactorjs/ofetch';
import { ApiClient } from '~/utils/api/ApiResources';

class ApiMoods extends ApiClient {
  constructor(api: FetchInstance) {
    super(api, 'moods');
  }

  index = () => this.api.get(this.url);
}

export default ApiMoods;
