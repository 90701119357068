<template>
  <component
    :is="tag"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
  import { cva, type VariantProps } from 'class-variance-authority';

  const containerVariants = cva('mx-auto w-full', {
    variants: {
      fluid: {
        true: 'max-w-full'
      },
      relative: {
        true: 'relative'
      },
      noGuttersX: {
        false: 'px-2.5 lg:px-6'
      },
      spacing: {
        base: 'md:mb-8 mb-4',
        main: 'md:my-8 my-2.5',
        none: ''
      },
      size: {
        xl: 'max-w-6xl',
        '2xl': 'max-w-7xl',
        base: 'max-w-8xl'
      }
    }
  });

  type ContainerVariantsProps = VariantProps<typeof containerVariants>;

  type Props = {
    tag?: keyof HTMLElementTagNameMap;
    fluid?: ContainerVariantsProps['fluid'];
    noGuttersX?: ContainerVariantsProps['noGuttersX'];
    relative?: ContainerVariantsProps['relative'];
    spacing?: ContainerVariantsProps['spacing'];
    size?: ContainerVariantsProps['size'];
  };

  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<Props>(), {
    tag: 'div',
    relative: true,
    spacing: 'base',
    size: 'base',
    noGuttersX: false,
    fluid: false
  });

  const attrs = useAttrs();

  const classes = computed(() => cn(containerVariants(props), attrs.class || ''));
</script>
