import type { FetchInstance } from '@refactorjs/ofetch';

export default (api: FetchInstance) => {
  const { isAuthenticated } = useSanctumAuth();
  const { SERVER_URL } = useRuntimeConfig().public;
  const getCsrf = () => {
    return api.get(SERVER_URL + '/sanctum/csrf-cookie');
  };

  const withCSRF = async (req: () => BaseResponse): BaseResponse => {
    if (!isAuthenticated.value) {
      await getCsrf();
    }
    return req();
  };

  return {
    getCsrf,
    forgotPassword: (body: BodyForgotPassword) => withCSRF(() => api.post('/forgot-password', { body })),
    resetPassword: (body: BodyResetPassword) => withCSRF(() => api.post('/reset-password', { body })),
    registration: (body: BodyRegistration) => withCSRF(() => api.post('/register', { body })),
    socialLogin: (body: BodySocialLogin) => withCSRF(() => api.post('/social-login', { body })),
    broadcastingAuth: (body: BodyBroadcastingAuth) => api.post('/broadcasting/auth', { body }),
    stopImpersonating: () => api.delete(SERVER_URL + '/nova-api/impersonate')
  };
};
