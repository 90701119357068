const useDashboardSettingsBreadcrumbs = (items: MaybeRef<BreadcrumbsItem[]> = []) => {
  return computed<BreadcrumbsItem[]>(() => {
    return [
      {
        to: { name: 'dashboard-settings' },
        name: 'Settings'
      },
      ...unref(items)
    ];
  });
};

export { useDashboardSettingsBreadcrumbs };
