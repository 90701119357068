import Pusher from 'pusher-js';

export default defineNuxtPlugin(() => {
  const { PUSHER_APP_KEY, PUSHER_HOST, PUSHER_PORT, PUSHER_FORCE_TLS, PUSHER_APP_CLUSTER } = useRuntimeConfig().public;

  const port = PUSHER_PORT ? Number(PUSHER_PORT) : undefined;

  const apiRoutes = useApiRoutes();
  const pusherClient = new Pusher(PUSHER_APP_KEY, {
    cluster: PUSHER_APP_CLUSTER,
    wsHost: PUSHER_HOST,
    wsPort: port,
    wssPort: port,
    forceTLS: PUSHER_FORCE_TLS,
    disableStats: true,
    enabledTransports: ['wss', 'ws'],
    channelAuthorization: {
      transport: 'jsonp',
      endpoint: '',
      customHandler: (params, fn) => {
        apiRoutes.auth
          .broadcastingAuth({
            socket_id: params.socketId,
            channel_name: params.channelName
          })
          .then(res => {
            fn(null, res._data);
          })
          .catch((error: any) => {
            fn(error, null);
          });
      }
    }
  });

  return {
    provide: {
      pusherClient
    }
  };
});
