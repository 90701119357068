type FilesDataItem = {
  ext: string;
  accept: string;
  officePreview?: boolean;
};
const FilesData = {
  docs: [
    {
      ext: 'doc',
      accept: 'application/msword',
      officePreview: true
    },
    {
      ext: 'docx',
      accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      officePreview: true
    },
    {
      ext: 'rtf',
      accept: 'application/rtf'
    },
    {
      ext: 'txt',
      accept: 'text/plain'
    },
    {
      ext: 'ppt',
      accept: 'application/vnd.ms-powerpoint'
    },
    {
      ext: 'pptx',
      accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      officePreview: true
    },
    {
      ext: 'odt',
      accept: 'application/vnd.oasis.opendocument.text',
      officePreview: true
    },
    {
      ext: 'ods',
      accept: 'application/vnd.oasis.opendocument.spreadsheet',
      officePreview: true
    }
  ],
  pdf: [
    {
      ext: 'pdf',
      accept: 'application/pdf'
    }
  ],
  sheets: [
    {
      ext: 'xls',
      accept: 'application/vnd.ms-excel',
      officePreview: true
    },
    {
      ext: 'xlsx',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      officePreview: true
    },
    {
      ext: 'csv',
      accept: 'text/csv'
    }
  ],
  img: [
    {
      ext: 'jpg',
      accept: 'image/jpg'
    },
    {
      ext: 'jpeg',
      accept: 'image/jpeg'
    },
    {
      ext: 'png',
      accept: 'image/png'
    },
    {
      ext: 'webp',
      accept: 'image/webp'
    }
  ],
  otherImage: [
    {
      ext: 'gif',
      accept: 'image/gif'
    },
    {
      ext: 'ico',
      accept: 'image/x-icon'
    },
    {
      ext: 'svg',
      accept: 'image/svg+xml'
    }
  ],
  audio: [
    {
      ext: 'mp3',
      accept: 'audio/mpeg'
    }
  ],
  video: [
    {
      ext: 'mpg',
      accept: 'video/mpeg'
    },
    {
      ext: 'mpeg',
      accept: 'video/mpeg'
    },
    {
      ext: 'mp4',
      accept: 'video/mp4'
    },
    {
      ext: 'mov',
      accept: 'video/quicktime'
    },
    {
      ext: 'wmv',
      accept: 'video/x-ms-wmv'
    }
  ]
} as const;
type KFilesData = keyof typeof FilesData | 'other';

const MaxFileUploadSize = 1024 * 1024 * 10;
const MaxFileLimit = 40;

export { FilesData, MaxFileUploadSize, MaxFileLimit, type KFilesData, type FilesDataItem };
