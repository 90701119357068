<template>
  <span
    v-if="isCustom"
    class="app-icon app-icon--svg"
    :class="[
      appClass,
      {
        'app-icon--font-size': !customSize,
        'app-icon--filled': !filled,
        'app-icon--filled-deep': !filled && !notDeepColor,
        'app-icon--stroke': hasStroke && !filled
      }
    ]"
    v-html="icon"
  />
  <i
    v-else
    :class="appClass"
    class="leading-none app-icon--material"
  >
    <span class="material-icons">
      {{ name }}
    </span>
  </i>
</template>

<script setup lang="ts">
  import { type IconType } from '~/globals/icons';

  const props = withDefaults(
    defineProps<{
      name: IconName;
      filled?: boolean;
      notDeepColor?: boolean;
      customSize?: boolean;
      type?: IconType;
    }>(),
    {
      filled: false,
      notDeep: false,
      type: 'material'
    }
  );

  const appClass = 'inline-flex items-center justify-center';
  const icon = shallowRef<string | Record<string, any>>('');
  const hasStroke = ref(false);

  const isCustom = props.type === 'custom';

  if (isCustom) {
    const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
      query: '?raw',
      import: 'default',
      eager: false
    });
    async function getIcon() {
      try {
        const rawIcon = (await iconsImport[`/assets/icons/${props.type}/${props.name}.svg`]()) as string;
        if (rawIcon.includes('stroke')) {
          hasStroke.value = true;
        }
        icon.value = rawIcon;
      } catch {
        console.error(`[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/icons/${props.type}'`);
      }
    }

    watch(() => [props.name, props.type], getIcon);

    onMounted(() => getIcon());
  }
</script>

<style scoped lang="scss">
  .material-icons {
    font-size: inherit;
    width: 1em;
    height: 1em;
  }
</style>
