<template>
  <common-profile-settings-content-wrap
    :breadcrumbs="breadcrumbs"
    full
  >
    <common-content-privacy-policy class="max-h-screen overflow-auto" />
  </common-profile-settings-content-wrap>
</template>
<script setup lang="ts">
  const breadcrumbs = useDashboardSettingsBreadcrumbs([
    {
      to: { name: 'dashboard-settings-privacy-policy' },
      disabled: false,
      name: 'Privacy Policy'
    }
  ]);
  useSeoMeta({
    title: 'Privacy Policy'
  });
</script>
