export default defineNuxtRouteMiddleware(to => {
  const { isAuthenticated, user } = useSanctumAuth<User>();

  if (isAuthenticated.value) {
    const userId = useCookieFirstLogin();
    const path = '/dashboard/setup-profile';
    const isValidUser = useValidUser();
    const saveUser = () => {
      if (!userId.value && user.value?.id) {
        userId.value = String(user.value.id);
      }
    };

    if (!isValidUser.value && !userId.value && to.path !== path) {
      saveUser();
      return navigateTo(path);
    }
    if (isValidUser.value) {
      saveUser();
    }
  }
});
