<template>
  <div>
    <ui-typography
      variant="small"
      class="overflow-hidden text-ellipsis text-nowrap max-w-28"
    >
      <b>{{ name }}</b>
      <br />
      <span class="text-gray-300">
        {{ fileSize }}
      </span>
    </ui-typography>
    <slot />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    size: number;
    name: string;
  };
  const props = defineProps<Props>();
  const fileSize = computed(() => formatFileSize(props.size));
</script>
