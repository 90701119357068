const RoomType = {
  Room: 1,
  RoomIdea: 2
} as const;

const RoomAdminPermission = {
  UpdateRoomDetails: 'update_room_details',
  DeleteMessages: 'delete_messages',
  DeleteMembers: 'delete_members',
  ApproveRequests: 'approve_requests',
  RejectRequests: 'reject_requests',
  DeleteRoom: 'delete_room'
} as const;

const RoomUserStatus = {
  Owner: 1,
  Admin: 2,
  Member: 3,
  Waiting: 4
};

const RoomUsersFilterQuery = {
  Members: 'members',
  Requests: 'requests',
  Admins: 'admins'
};

const MessageAttachmentProgress = {
  Pending: 1,
  Upload: 2,
  Error: 3,
  Success: 4
} as const;

type TRoomType = (typeof RoomType)[keyof typeof RoomType];
type TRoomAdminPermission = (typeof RoomAdminPermission)[keyof typeof RoomAdminPermission];
type TRoomUserStatus = (typeof RoomUserStatus)[keyof typeof RoomUserStatus];
type TRoomUsersFilterQuery = (typeof RoomUsersFilterQuery)[keyof typeof RoomUsersFilterQuery];
type TMessageAttachmentProgress = (typeof MessageAttachmentProgress)[keyof typeof MessageAttachmentProgress];

export { RoomType, RoomAdminPermission, RoomUserStatus, RoomUsersFilterQuery, MessageAttachmentProgress };

export type { TRoomType, TRoomAdminPermission, TRoomUserStatus, TRoomUsersFilterQuery, TMessageAttachmentProgress };
