<template>
  <label
    :class="labelClasses"
    :data-ui="dataUi"
  >
    <slot>
      {{ text && $t(text) }}
    </slot>
    <span v-if="required">*</span>
  </label>
</template>

<script setup lang="ts">
  import { cva, cx } from 'class-variance-authority';

  type Props = {
    required?: boolean;
    floating?: boolean;
    floatingTop?: boolean;
    control?: boolean;
    error?: boolean;
    text?: string;
    placeholderShow?: boolean;
    focus?: boolean;
  };

  const labelVariants = cva('font-medium inline-flex gap-1', {
    variants: {
      variant: {
        floating:
          'md:text-lg text-sm absolute rounded-lg px-2.5 left-5 leading-1 text-input-text origin-[0] transition-[transform] ease-in-out duration-300 bg-input-bg top-0 scale-100 left-auto data-focus:left-5 data-focus:px-2.5 data-focus:-translate-y-4 data-focus:top-0 data-focus:scale-75 data-focus:text-input-active-text data-shown:left-5 data-shown:px-2.5 data-shown:-translate-y-4 data-shown:top-0 data-shown:scale-75 data-shown:text-input-active-text',
        base: 'md:text-2xl text-base mb-0.5 md:ml-8 ml-5 text-input-text',
        control: 'md:text-sm text-xs text-input-text'
      },
      floating: {
        center: 'top-1/2 -translate-y-1/2',
        top: 'top-1.5 -translate-y-0'
      }
    }
  });

  const dataUi = computed(() =>
    cx({
      focus: props.focus,
      shown: props.placeholderShow
    })
  );

  const props = withDefaults(defineProps<Props>(), {
    text: props => props.text || ''
  });

  const labelClasses = computed(() => {
    const variants: Keyable<string> = {
      variant: props.control ? 'control' : 'base'
    };
    if (props.floating) {
      variants.variant = 'floating';
      variants.floating = props.floatingTop ? 'top' : 'center';
    }
    return cn(labelVariants(variants), props.error && '!text-input-error-text');
  });
</script>
