import type { TRoomFilterType } from '~/globals/RoomFilterType';

const makeState = <T = any>(initialVal: T) =>
  useTransform(
    RoomFilterType,
    (acc, type) => {
      acc[type] = initialVal;
      return acc;
    },
    {} as Record<TRoomFilterType, T>
  );

type Rooms = Room | RoomPreview;

const useMyRoomStore = defineStore(
  'my-rooms',
  () => {
    const appStore = useAppStore();
    const apiRoutes = useApiRoutes();
    const roomStore = useRoomStore();
    const roomsStore = useRoomsStore();
    const { user, onUpdateUser } = useAuthUser();
    const activeType = ref<TRoomFilterType>(RoomFilterType.Member);
    const rooms = reactive(makeState<Rooms[]>([]));
    const loadings = reactive(makeState<boolean>(false));

    const fetchRooms = async (type: TRoomFilterType = activeType.value, backgroundLoad = false) => {
      if (loadings[type] || appStore.isDisconnect) {
        return false;
      }
      if (!backgroundLoad) {
        loadings[type] = true;
      }

      const res = await apiRoutes.profile.getPrivateRooms({
        type
      });
      const newRoms = res._data.data;
      setRooms(newRoms, type);
      if (roomStore.room && !roomStore.loading) {
        const room = useFind(newRoms, { id: roomStore.roomId });
        if (room) {
          roomStore.updateRoom(room);
        }
      }

      if (!roomsStore.loading && roomsStore.rooms.length) {
        roomsStore.setRooms(updateItemsArray(roomsStore.rooms, newRoms));
      }

      loadings[type] = false;
      return true;
    };

    const setRooms = (newRooms: Rooms[], type: TRoomFilterType = activeType.value) => {
      rooms[type] = newRooms;
    };

    const addRoom = (newRoom: Rooms, type: TRoomFilterType = activeType.value) => {
      rooms[type] = upsert(rooms[type], newRoom);

      onUpdateUser({
        rooms_as_owner_count: (user.value?.rooms_as_owner_count || 0) + 1
      });
    };

    const updateRoomByType = (room: Partial<Room>, type: TRoomFilterType = activeType.value) => {
      const roomIndex = useFindIndex(rooms[type], { id: room.id });

      if (roomIndex >= 0) {
        rooms[type][roomIndex] = mergeExcludeArray(rooms[type][roomIndex], room);
      }
    };
    const updateRoom = (room?: Partial<Room>) => {
      if (room) {
        useEach(RoomFilterType, type => {
          updateRoomByType(room, type);
        });
      }
    };

    const deleteRoomByType = (roomId: number, type: TRoomFilterType = activeType.value) => {
      rooms[type] = removeItemArray(rooms[type], roomId);

      onUpdateUser({
        rooms_as_owner_count: Math.max((user.value?.rooms_as_owner_count || 0) - 1, 0)
      });
    };

    const deleteRoom = (roomId: number) => {
      useEach(RoomFilterType, type => {
        deleteRoomByType(roomId, type);
      });
    };

    const setActiveType = (type: TRoomFilterType) => {
      activeType.value = type;
    };

    const findRoom = (roomId: number) => useFind(Object.values(rooms).flat(), { id: roomId });

    return {
      rooms,
      loadings,
      activeType,

      setActiveType,
      addRoom,
      setRooms,
      fetchRooms,
      updateRoomByType,
      updateRoom,
      deleteRoomByType,
      deleteRoom,
      findRoom
    };
  },
  {
    persist: {
      key: 'myRoomStore',
      paths: ['activeType']
    }
  }
);

export default useMyRoomStore;
