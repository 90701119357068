<template>
  <transition-expand>
    <div
      v-if="isShow"
      :class="classes"
      v-bind="$attrs"
    >
      <div
        v-if="$slots.icon || calcIcon"
        :class="iconClass"
      >
        <slot name="icon">
          <ui-icon
            v-if="calcIcon"
            :name="calcIcon"
          />
        </slot>
      </div>
      <div class="flex flex-col gap-1.5">
        <ui-typography
          v-if="title || $slots.title"
          variant="h4"
          tag="h4"
        >
          <slot name="title">
            {{ $t(title || '') }}
          </slot>
        </ui-typography>
        <ui-typography v-if="body || $slots.default">
          <slot>
            {{ $t(body || '') }}
          </slot>
        </ui-typography>
      </div>
      <div
        v-if="!hideClose"
        class="absolute top-2 right-2 md:top-[18px] md:right-5"
        :class="iconClass"
      >
        <slot
          name="btn-close"
          :on-close="onClose"
        >
          <button
            class="hover:opacity-60 focus:opacity-80 w-5 h-5 flex items-center justify-center"
            @click="onClose"
          >
            <ui-icon name="close" />
          </button>
        </slot>
      </div>
    </div>
  </transition-expand>
</template>

<script setup lang="ts">
  import { cva, type VariantProps } from 'class-variance-authority';

  const alertIconVariants = cva('flex items-center', {
    variants: {
      icon: {
        error: 'text-error',
        warning: 'text-warning',
        info: 'text-info',
        success: 'text-success',
        default: 'text-body-text'
      }
    }
  });
  const alertVariants = cva('flex items-center py-2.5 px-3 md:py-5 md:px-8 gap-1.5 relative rounded-md md:rounded-xl', {
    variants: {
      type: {
        error: 'bg-error-light text-error',
        warning: 'bg-warning-light text-warning',
        info: 'bg-info-light text-info',
        success: 'bg-success-light text-success',
        default: 'bg-card text-body-text'
      }
    }
  });

  type AlertVariantsProps = VariantProps<typeof alertVariants>;

  type Props = {
    type?: AlertVariantsProps['type'];
    variant?: 'contained' | 'ghost';
    icon?: IconName;
    title?: string;
    body?: string;
    hideClose?: boolean;
    modelValue?: boolean;
  };

  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<Props>(), {
    type: 'info',
    variant: 'contained',
    modelValue: true
  });

  const emit = defineEmits(['update:modelValue']);

  const isShow = useVModel(props, 'modelValue', emit, {
    passive: true
  });

  const classes = computed(() => {
    return cn(
      alertVariants({ type: props.type }),
      props.variant === 'ghost' && 'bg-card text-body-text',
      !props.hideClose && 'md:pr-12 pr-6'
    );
  });

  const iconClass = computed(() => {
    return alertIconVariants({ icon: props.type });
  });

  const calcIcon = computed(() => {
    if (props.icon) {
      return props.icon;
    }
    if (props.type) {
      return {
        error: 'error',
        warning: 'warning',
        info: 'error',
        success: 'check_circle',
        default: 'error'
      }[props.type] as IconName;
    }
    return null;
  });

  const onClose = () => {
    isShow.value = false;
  };
</script>
