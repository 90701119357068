import VueDOMPurifyHTML, { type DirectiveConfig } from 'vue-dompurify-html';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(VueDOMPurifyHTML, {
    namedConfigurations: {
      svg: {
        USE_PROFILES: { svg: true }
      }
    }
  } as DirectiveConfig);
});
