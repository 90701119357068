import { serialize, type Options } from 'object-to-formdata';
const fileToFormData = (file: File, name = 'file') => {
  const formData = new FormData();
  formData.append(name, file);

  return formData;
};

const objToFormData = (data: Keyable, options?: Options) => {
  const parsOptions = {
    indices: true,
    booleansAsIntegers: true,
    allowEmptyArrays: true,
    ...(options || {})
  };

  return serialize(replaceEmptyArraysAndObjects(data), parsOptions);
};

export { fileToFormData, objToFormData };
