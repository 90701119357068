import { useField, type SubmissionContext } from 'vee-validate';

type SubmitHandlerOptions = {
  isResetForm: boolean;
  isCleanData: MaybeRef<boolean>;
  isShowToastError: boolean;
  successToast: boolean | string;
  excludeErrorKeys?: string[];
  handlerOptions: Partial<HandlerOptions>;
  onNotUpdate: () => any;
  onSuccess: (args: any) => any;
  onError: (args: any) => any;
  onValidate: (form: any) => boolean;
};

const submitHandlerDefaultOptions: SubmitHandlerOptions = {
  isResetForm: false,
  isShowToastError: true,
  isCleanData: false,
  successToast: false,
  handlerOptions: {
    showServerError: false
  },
  onNotUpdate: () => ({}),
  onSuccess: () => ({}),
  onError: () => ({}),
  onValidate: () => true
};

const useScrollToFirstError = (refElem?: Ref<HTMLElement | null>) => {
  const internalInstance = getCurrentInstance();
  return (intoView = true) => {
    const el = unref(refElem) || internalInstance?.vnode?.el;
    if (el && el instanceof HTMLElement) {
      const errorEl = el.querySelector('.field-error');
      if (!errorEl) {
        return;
      }
      if (intoView) {
        errorEl.scrollIntoView({
          behavior: 'smooth'
        });
        return;
      }
      scrollToEl(errorEl);
    }
  };
};

const useHandleUpdateFormValues = (values: Ref<any>, initValues?: MaybeRef<any>, excludeKeys?: string[]) => {
  const isUpdateForm = ref(false);

  watchDebounced(
    values,
    () => {
      if (excludeKeys?.length) {
        isUpdateForm.value = !isEqual(useOmit(values.value, excludeKeys), useOmit(unref(initValues), excludeKeys));
        return;
      }
      isUpdateForm.value = !isEqual(values.value, unref(initValues));
    },
    { flush: 'post', debounce: 400 }
  );
  // Todo:: add handle route leave
  // useRouterConfirmLeave(isUpdateForm, () => {
  //   isUpdateForm.value = false;
  // });
  return isUpdateForm;
};

const useHandleFormAsyncError = (
  formFields: MaybeRef<Keyable<any>>,
  excludeKeys?: SubmitHandlerOptions['excludeErrorKeys']
) => {
  const toast = useToast();
  const getMessageErrorByResponse = useGetMessageErrorByResponse();
  const onScrollToError = useScrollToFirstError();
  return (res: { error: any; setErrors: Function; isShowToast: boolean; response: any }) => {
    const { error, setErrors, isShowToast = true, response } = res;
    if (error) {
      const errors = error?.errors;
      let errorSnackbar = getMessageErrorByResponse(response);
      if (errors) {
        setErrors(
          useTransform(
            errors,
            (acc: any, val, key) => {
              acc[key] = isArray(val) ? val[0] : val;
            },
            []
          )
        );

        const emptyKey = useFindKey(errors, (_, key) => {
          if (excludeKeys && useSome(excludeKeys, innerKey => key.includes(innerKey))) {
            return true;
          }
          return !useHasIn(unref(formFields), key);
        });
        if (emptyKey) {
          errorSnackbar = errors?.[emptyKey] || errorSnackbar;
          if (errors?.[emptyKey]) {
            errorSnackbar = isArray(errors?.[emptyKey]) ? errors?.[emptyKey][0] : errors?.[emptyKey];
          }
        } else {
          errorSnackbar = '';
          nextTick(onScrollToError);
        }
      }

      if (isShowToast && errorSnackbar) {
        toast.error(errorSnackbar);
      }
    }
  };
};

const useSubmitHandler = <T = unknown>(
  action: (...args: any[]) => Promise<T> | T,
  formFields: MaybeRef<Keyable<any>>,
  options: Partial<SubmitHandlerOptions> = {}
) => {
  const {
    isResetForm,
    isCleanData,
    successToast,
    isShowToastError,
    onSuccess,
    onError,
    onNotUpdate,
    onValidate,
    excludeErrorKeys,
    handlerOptions
  } = {
    ...submitHandlerDefaultOptions,
    ...options
  };
  const handler = useHandleRequest();
  const { t } = useI18n();
  const { success } = useToast();
  const handleFormAsyncError = useHandleFormAsyncError(formFields, excludeErrorKeys);

  const onSuccessToast = () => {
    if (!successToast) {
      return;
    }

    success(t(isString(successToast) ? successToast : 'Submitted'));
  };

  const onSubmit = async (data: any) => {
    return action(data);
  };

  return async (data: any, ctx: SubmissionContext) => {
    const { setErrors, resetForm } = ctx;
    let prepareData = data;
    if (unref(isCleanData)) {
      prepareData = getDifferenceObject(data, unref(formFields));
      if (isEmpty(prepareData)) {
        onNotUpdate();
        return;
      }
    }

    if (!onValidate(prepareData)) {
      return;
    }

    const res = await handler(() => onSubmit(prepareData), handlerOptions);
    if (res) {
      handleFormAsyncError({
        error: res.isError ? res.data : null,
        response: res.response,
        setErrors,
        isShowToast: isShowToastError
      });
      if (!res.isError) {
        onSuccess(res.data);
        onSuccessToast();
        if (isResetForm) {
          resetForm({ values: unref(formFields) });
        }
      } else {
        onError(res);
      }

      return res;
    }
  };
};

const useSyncFormField = (
  parenFieldName: MaybeRef<string>,
  syncFieldName: MaybeRef<string>,
  transform?: (val: any) => any
) => {
  const { value: parentValue } = useField(parenFieldName);
  const { value: syncValue } = useField(syncFieldName);

  return syncRef(parentValue, syncValue, {
    immediate: false,
    direction: 'ltr',
    transform: {
      ltr: transform
    }
  });
};

export { useSubmitHandler, useHandleUpdateFormValues, useSyncFormField, useScrollToFirstError };
