<template>
  <ui-container class="bg-card text-body-text md:py-14 py-6 rounded-lg min-h-64 flex flex-col justify-center shrink-0">
    <div class="flex justify-center mb-3">
      <ui-error-icon message="Offline" />
    </div>
    <div class="max-w-2xl text-center mx-auto">
      <ui-typography
        variant="h3"
        class="text-center mb-2"
      >
        You are offline. Check your connection.
      </ui-typography>

      <ui-button
        text="Try again"
        @click="onReload"
      />
    </div>
  </ui-container>
</template>

<script setup lang="ts">
  const routeName = useCurrentRouteName();
  const localePath = useLocalePath();

  const onReload = () => {
    if (routeName.value === 'offline') {
      window.location.href = localePath('/') || '';
      return;
    }
    window.location.reload();
  };
</script>
