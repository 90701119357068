import type { FetchInstance } from '@refactorjs/ofetch';
import { ApiClient } from '~/utils/api/ApiResources';

class ApiMoods extends ApiClient {
  constructor(api: FetchInstance) {
    super(api, 'profiles');
  }

  show = (id: number | string) => this.api.get(`${this.url}/${id}`);
}

export default ApiMoods;
