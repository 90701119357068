type SearchQuery = {
  q: string;
  limit?: number;
  pos?: string;
};

export default () => {
  const nuxtApp = useNuxtApp();
  const { GOOGLE_TENOR_API_KEY, APP_NAME, MODE } = useRuntimeConfig().public;
  const api = nuxtApp.$http.create({
    baseURL: 'https://tenor.googleapis.com/v2',
    query: {
      key: GOOGLE_TENOR_API_KEY,
      client_key: APP_NAME + MODE
    }
  });

  const withDefaultQuery = (query: Partial<SearchQuery> = {}) => {
    return { limit: 18, contentfilter: 'low', ar_range: 'wide', media_filter: 'webp,tinygif,nanogif', ...query };
  };

  return {
    autocomplete: (q: string) =>
      api.get('/autocomplete', { query: { q, locale: nuxtApp.$i18n?.locale?.value || 'en_US' } }),
    search: (query: SearchQuery) => api.get('/search', { query: withDefaultQuery(query) }),
    searchSuggestions: (query: SearchQuery) => api.get('/search_suggestions', { query: withDefaultQuery(query) }),
    featured: (query?: Omit<SearchQuery, 'q'>) => api.get('/featured', { query: withDefaultQuery(query) }),
    categories: () => api.get('/categories'),
    registershare: (id: string, q: string) =>
      api.get('/registershare', {
        query: {
          id,
          q
        }
      })
  };
};
