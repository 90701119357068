export const getUserFullName = (baseUser?: Partial<User> | null): string => {
  const user = objectKeysToCamelCase(baseUser);
  let name = user?.username || '';
  if (user?.firstName || user?.lastName) {
    name = user.firstName ? user.firstName + ' ' : '';
    name = name + (user.lastName ? user.lastName + ' ' : '');
  }
  return name;
};

const useUserFullName = (props: MaybeRef<Keyable>, key: string) => {
  const user = useProp<User>(props, key);
  return computed(() => getUserFullName(user.value));
};

export default useUserFullName;
