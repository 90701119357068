import type { FetchInstance } from '@refactorjs/ofetch';
import { excludeMethods } from '~/utils/api/helpers';

@excludeMethods(['show', 'update'])
class ApiInterests extends ApiResources {
  constructor(api: FetchInstance) {
    super(api, 'interests');
  }
}

export default ApiInterests;
