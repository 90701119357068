type ApiResourcesMethod = 'index' | 'show' | 'create' | 'update' | 'delete';

function excludeMethods(methods: ApiResourcesMethod[]) {
  return function <T extends { new (...args: any[]): any }>(constructor: T) {
    return class extends constructor {
      constructor(...args: any[]) {
        super(...args);

        methods.forEach(method => {
          this[method] = () => {
            throw new Error(`Method ${method} is excluded for this instance.`);
          };
        });
      }
    };
  };
}
export { excludeMethods };
