import type { FetchConfig, FetchInstance } from '@refactorjs/ofetch';
import type { FetchResponse } from 'ofetch';
import type { TRoomAdminPermission, TRoomUsersFilterQuery } from '~/globals/rooms';

class ApiRooms extends ApiResources {
  constructor(api: FetchInstance) {
    super(api, 'rooms', { isFile: true });
  }

  private getRoomPath = (roomId: ResourceId, resource?: string) => {
    const path = `${this.url}/${roomId}`;
    if (resource) {
      return `${path}/${resource}`;
    }
    return path;
  };

  getRoomInterests = (roomId: ResourceId) => {
    return this.api.get(this.getRoomPath(roomId, 'interests'));
  };

  // Messages

  private getMessagesPath = (roomId: ResourceId, messageId?: ResourceId) => {
    const path = this.getRoomPath(roomId, 'messages');
    if (messageId) {
      return `${path}/${messageId}`;
    }
    return path;
  };

  getMessages = (roomId: ResourceId, params: FetchMessagesParams) => {
    return this.api.get(this.getMessagesPath(roomId), { params }) as Promise<
      FetchResponse<ResWithCursorPagination<Message[]>>
    >;
  };

  createMessage = (roomId: ResourceId, body: CreateMessage) => {
    return this.api.post(this.getMessagesPath(roomId), {
      body
    });
  };

  updateMessage = (roomId: ResourceId, messageId: ResourceId, body: CreateMessage) => {
    return this.api.put(this.getMessagesPath(roomId, messageId), {
      body
    });
  };

  deleteMessage = (roomId: ResourceId, messageId: ResourceId) => {
    return this.api.delete(this.getMessagesPath(roomId, messageId));
  };

  bulkMessageRead = (roomId: ResourceId, messageIds: ResourceId[]) => {
    return this.api.post(this.getRoomPath(roomId, 'mass-messages'), {
      body: {
        message_ids: messageIds
      }
    });
  };

  bulkMessageDelete = (roomId: ResourceId, messageIds: ResourceId[]) => {
    return this.api.delete(this.getRoomPath(roomId, 'mass-messages'), {
      body: {
        message_ids: messageIds
      }
    });
  };

  createMessageAttachment = (roomId: ResourceId, messageId: ResourceId, config: FetchConfig) => {
    return this.api.post(this.getMessagesPath(roomId, messageId) + '/attachments', config);
  };

  deleteMessageAttachment = (roomId: ResourceId, messageId: ResourceId, attachmentId: ResourceId) => {
    return this.api.delete(this.getMessagesPath(roomId, messageId) + '/attachments/' + attachmentId);
  };

  // Messages

  // Admins
  private getUsersPath = (roomId: ResourceId, userId?: ResourceId) => {
    const path = this.getRoomPath(roomId, 'users');
    if (userId) {
      return `${path}/${userId}`;
    }
    return path;
  };

  setUserPermissions = (
    roomId: ResourceId,
    userId: ResourceId,
    permissions: TRoomAdminPermission[],
    isCreate: boolean
  ) => {
    // Todo: sync with BE
    const req = isCreate ? this.api.post : this.api.put;

    return req(this.getUsersPath(roomId, userId) + '/admin', {
      body: {
        permissions
      }
    });
  };

  deleteUserFromAdmin = (roomId: ResourceId, userId: ResourceId) => {
    return this.api.delete(this.getUsersPath(roomId, userId) + '/admin');
  };

  approveUser = (roomId: ResourceId, userId: ResourceId) => {
    return this.api.post(this.getUsersPath(roomId, userId) + '/approve');
  };

  rejectUser = (roomId: ResourceId, userId: ResourceId) => {
    return this.api.post(this.getUsersPath(roomId, userId) + '/reject');
  };

  // User
  getMembers = (
    roomId: ResourceId,
    params?: { type?: TRoomUsersFilterQuery; search?: string; cursor?: string; per_page?: number }
  ) => {
    return this.api.get(this.getUsersPath(roomId), { params });
  };

  join = (roomId: ResourceId) => {
    return this.api.post(this.getRoomPath(roomId, 'join'));
  };

  leave = (roomId: ResourceId) => {
    return this.api.post(this.getRoomPath(roomId, 'leave'));
  };

  getPrivateInterests = (roomId: ResourceId) => this.api.get(this.getRoomPath(roomId, 'interests'));
}

export default ApiRooms;
