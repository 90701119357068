import type { FetchInstance } from '@refactorjs/ofetch';

class ApiAttachments extends ApiResources {
  constructor(api: FetchInstance) {
    super(api, 'profile/attachments', { isFile: true });
  }

  defaultAvatars = () => this.api.get('d-avatars');
}

export default ApiAttachments;
