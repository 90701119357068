<template>
  <div class="w-full flex-1 flex flex-col h-full">
    <nuxt-page />
  </div>
</template>

<script setup lang="ts">
  const roomStore = useRoomStore();
  if (!roomStore.userStatus.owner) {
    await useRoomRedirect();
  }
</script>
