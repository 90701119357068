import { getComposer, useLocalePath as _useLocalePath, useRouteBaseName as _useRouteBaseName } from 'vue-i18n-routing';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { TypedRouteLocationRaw } from '@typed-router';

const useRouterLocale = () => {
  const localePath = useLocalePath();
  const routerPush = (to: TypedRouteLocationRaw) => {
    return navigateTo(localePath(to));
  };
  const routerReplace = (to: TypedRouteLocationRaw) => {
    return navigateTo(localePath(to), { replace: true });
  };
  const openRoutePathNewWindow = (to: TypedRouteLocationRaw) => {
    const path = localePath(to);
    if (isString(path)) {
      window.open(path, '_blank');
    }
  };

  return {
    localePath,
    routerPush,
    routerReplace,
    openRoutePathNewWindow
  };
};
const useRouteBaseNameServer = (route?: RouteLocationNormalizedLoaded) => {
  return _useRouteBaseName({
    router: useRouter(),
    route: route || useRoute(),
    i18n: getComposer(useNuxtApp().$i18n)
  } as any);
};
const useLocalePathServer = (route?: RouteLocationNormalizedLoaded) => {
  return _useLocalePath({
    router: useRouter(),
    route: route || useRoute(),
    i18n: getComposer(useNuxtApp().$i18n)
  } as any);
};

const useCurrentRouteName = () => {
  const route = useRoute();
  const getRouteBaseName = useRouteBaseName();
  return computed(() => getRouteBaseName(route as any) || '');
};

export { useRouteBaseNameServer, useLocalePathServer, useRouterLocale, useCurrentRouteName };
