const objectKeysToCamelCase = (obj: any) => useMapKeys(obj, (_, k) => useCamelCase(k));

const isNotEmptyDeep = (val: unknown) => {
  if (isObject(val)) {
    return useValues(val).some(isNotEmptyDeep);
  }
  return !(isNil(val) || val === '');
};

const getCleanFormValue = (
  newData: Keyable<any> = {},
  source: Keyable<any> = {},
  isDetectedObject = false,
  accumulator: any = {}
) => {
  if (isEmpty(newData)) {
    return source;
  }
  const initialData = useCloneDeep(source);
  return useTransform(
    initialData,
    (result, val, key) => {
      if (!useHas(newData, key)) {
        result[key] = val;
        return;
      }
      if (isDetectedObject && (isArray(val) || isPlainObject(val))) {
        result[key] = getCleanFormValue(
          isPlainObject(val) ? newData[key] : initialData[key],
          isPlainObject(val) ? initialData[key] : newData[key],
          true,
          isArray(val) ? [] : {}
        );
        return;
      }

      result[key] = newData[key];
    },
    accumulator
  );
};

const getDifferenceObject = (
  object: Keyable<any> = {},
  base: Keyable<any> = {},
  notDeepCheckArray = true,
  accumulator: Keyable<any> = {}
) => {
  return useTransform(
    object,
    (result, value, key) => {
      if (value !== base[key]) {
        if (isArray(value) && isArray(base[key])) {
          result[key] = notDeepCheckArray ? value : getDifferenceObject(value, base[key]);
          return;
        }
        if (isPlainObject(value) && isPlainObject(base[key])) {
          const differenceObject = getDifferenceObject(value, base[key]);
          if (!isEmpty(differenceObject)) {
            result[key] = differenceObject;
          }
          return;
        }
        result[key] = value;
      }
    },
    accumulator
  );
};

const typedObject = (defaultObj: Keyable<any> = {}, newObj: any) => {
  const accumulator: Keyable<any> = {};

  return useTransform(
    newObj,
    (acc, val, key) => {
      if (isArray(defaultObj[key])) {
        acc[key] = wrapInArray(val);
        return;
      }
      if (isNumber(defaultObj[key]) && !isNaN(val)) {
        acc[key] = Number(val);
        return;
      }
      if (isObject(defaultObj[key])) {
        acc[key] = typedObject(defaultObj[key], val);
        return;
      }
      acc[key] = val;
    },
    accumulator
  );
};

const cleanQueryParams = (params: Keyable) =>
  useOmitBy(useCloneDeep(params), (val: any) => {
    if (isString(val) && !val.trim()) {
      return true;
    }
    return isNil(val);
  });

const jsonClone = <T = any>(obj: T): T => JSON.parse(JSON.stringify(obj));

const replaceEmptyArraysAndObjects = (obj: Keyable): any => {
  if (!isObject(obj) && !isArray(obj)) {
    return obj;
  }

  return useMapValues(obj, value => {
    if (value instanceof File) {
      return value;
    }
    if (isEmpty(value) && (isArray(value) || isObject(value))) {
      return null;
    }
    if (isObject(value)) {
      return replaceEmptyArraysAndObjects(value as Keyable);
    }
    return value;
  });
};

export {
  objectKeysToCamelCase,
  getDifferenceObject,
  getCleanFormValue,
  isNotEmptyDeep,
  typedObject,
  cleanQueryParams,
  jsonClone,
  replaceEmptyArraysAndObjects
};
