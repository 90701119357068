const withOgImage = (image?: string) => {
  const requestURL = useRequestURL();
  defineOgImage({
    width: 1200,
    height: 630,
    url: image || requestURL.origin + '/assets/images/og-base-img.png'
  });
};

export default withOgImage;
