const DateFormats = {
  Base: 'MM/DD/YYYY',
  BaseWithoutHour: 'MM/DD/YYYY',
  BaseAndMinutes: 'MM/DD/YYYY, HH:mm',
  PrettyMonthAndMinutes: 'D MMM, HH:mm',
  PrettyMonthAndMinutesAndYear: 'M MMM YYYY, HH:mm',
  BaseMinuteAndHour: 'HH:mm',
  BaseDayAndMonth: 'DD MMM',
  BaseAndHour: 'HH:mm A, MMMM D, YYYY',
  BaseAndHourAndTimeZone: 'MM/DD/YYYY HH:mm A UTC Z',
  Pretty: 'DD MMMM YYYY',
  ISO_OnlyDate: 'YYYY-MM-DD',
  ChatWithCurrentYear: 'DD MMMM',
  ChatWithOldYear: 'DD MMMM YYYY'
} as const;

type TDateFormats = (typeof DateFormats)[keyof typeof DateFormats];

export { DateFormats, type TDateFormats };
