import { defineNuxtRouteMiddleware, navigateTo, createError } from '#app';
import type { RouteLocationRaw } from 'vue-router';
import { useSanctumAuth } from '../composables/useSanctumAuth';
import { useSanctumConfig } from '../composables/useSanctumConfig';
import useAddCookieAuthRedirect from '../composables/useAddCookieAuthRedirect';

export default defineNuxtRouteMiddleware(to => {
  const { isAuthenticated, isVerify } = useSanctumAuth();
  const addCookieAuthRedirect = useAddCookieAuthRedirect();
  const options = useSanctumConfig();

  if (isAuthenticated.value) {
    if (!isVerify.value && !to.meta.auth?.ignoreVerify) {
      const redirectPath = options.redirect.onNotVerified;
      if (redirectPath) {
        return navigateTo(redirectPath, { replace: true });
      }
      throw createError({ statusCode: 403 });
    }

    return;
  }

  const endpoint = options.redirect.onAuthOnly;

  if (endpoint === false) {
    throw createError({ statusCode: 403 });
  }

  const redirect: RouteLocationRaw = { path: endpoint || '' };

  if (options.redirect.keepRequestedRoute) {
    addCookieAuthRedirect(to);
  }

  return navigateTo(redirect, { replace: true });
});
