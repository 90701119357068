import type { FetchConfig, FetchInstance } from '@refactorjs/ofetch';
import type { SearchParameters } from 'ofetch';
import type { Options as ObjectToFormdataOptions } from 'object-to-formdata';

type Options = {
  isFile?: boolean;
  optionsFormDataSerialize?: ObjectToFormdataOptions;
};

export abstract class ApiClient {
  api: FetchInstance;
  resource: string;
  isFile: boolean;
  objectToFormdataOptions?: ObjectToFormdataOptions;
  protected constructor(api: FetchInstance, resource: string, options?: Options) {
    this.api = api;
    this.resource = resource;
    this.isFile = !!options?.isFile;
    this.objectToFormdataOptions = options?.optionsFormDataSerialize;
  }

  get url() {
    return this.resource;
  }

  prepareBody(body: RequestBody) {
    return this.isFile ? objToFormData(body as Keyable, this.objectToFormdataOptions) : body;
  }

  prepareReqUpdateFile(body: any) {
    if (this.isFile) {
      return {
        body: body instanceof File ? fileToFormData(body) : objToFormData(body, this.objectToFormdataOptions),
        params: {
          _method: 'PUT'
        }
      };
    }
    return {
      body
    };
  }
}

abstract class ApiResources extends ApiClient {
  /**
   * Sends a GET request to retrieve a list of resources.
   * @param query Optional query parameters.
   * @returns BaseResponse object containing the result of the index operation.
   */
  public index = <D = any>(query?: SearchParameters, config: FetchConfig = {}): BaseResponse<D> => {
    return this.api.get(this.url, { ...config, query });
  };

  /**
   * Sends a GET request to retrieve a specific resource.
   * @param id The ID of the resource to retrieve.
   * @param query Optional query parameters.
   * @returns BaseResponse object containing the result of the show operation.
   */
  public show = <D = any>(
    idOrQuery?: string | number | SearchParameters,
    query?: SearchParameters,
    config: FetchConfig = {}
  ): BaseResponse<D> => {
    if (typeof idOrQuery === 'object' || idOrQuery === undefined) {
      return this.api.get(this.url, { ...config, query: idOrQuery });
    }
    return this.api.get(`${this.url}/${idOrQuery}`, { ...config, query });
  };

  /**
   * Sends a POST request to create a resource.
   * @param body Request body containing the data for the new resource.
   * @returns BaseResponse object containing the result of the create operation.
   */
  public create = <D = any>(body: RequestBody): BaseResponse<D> => {
    return this.api.post(this.url, { body: this.prepareBody(body) });
  };

  /**
   * Sends a PUT request to update a resource.
   * @param idOrBody The ID or request body.
   * @param body Optional request body if ID is provided.
   * @returns BaseResponse object.
   */
  public update = <D = any>(idOrBody?: string | number | RequestBody, body?: RequestBody): BaseResponse<D> => {
    if (!idOrBody) {
      return this.api.put(this.url);
    }
    if (typeof idOrBody === 'object') {
      return this.api.put(this.url, this.prepareReqUpdateFile(idOrBody));
    }
    return this.api.put(`${this.url}/${idOrBody}`, this.prepareReqUpdateFile(body));
  };

  /**
   * Sends a DELETE request to delete a resource.
   * @param id Optional ID of the resource to delete.
   * @returns BaseResponse object.
   */
  public delete = <D = any>(id?: number | string): BaseResponse<D> => {
    return this.api.delete(id ? `${this.url}/${id}` : this.url);
  };
}

export default ApiResources;
