type UpsertOptions<P, K> = {
  findKey?: K | K[];
  prepareUpdate?: (data: P) => P;
  isMerge?: boolean;
  unshift?: boolean;
};

const mergeExcludeArray = <T = any>(src: T, element: Partial<T>) =>
  useMergeWith(src, element, (_val, newVal) => {
    if (isArray(newVal)) {
      return newVal;
    }
  });

const findElementIndex = <P extends object, K extends keyof P>(array: P[], element: P, findKey: K | K[]): number => {
  return array.findIndex(_element => {
    if (Array.isArray(findKey)) {
      return findKey.some(key => !isNil(_element[key]) && _element[key] === element[key]);
    }
    return _element[findKey] === element[findKey];
  });
};

const upsertElement = <P extends object>(
  array: P[],
  element: P,
  index: number,
  options?: UpsertOptions<P, keyof P>
) => {
  const { isMerge, prepareUpdate } = options || {};

  if (index > -1) {
    if (isMerge) {
      array[index] = mergeExcludeArray<P>(array[index], element);
    } else if (prepareUpdate) {
      array[index] = prepareUpdate(array[index]);
    } else {
      array[index] = element;
    }
  } else {
    options?.unshift ? array.unshift(element) : array.push(element);
  }
};

const upsert = <P extends object, K extends keyof P>(arr: P[] = [], element: P, options?: UpsertOptions<P, K>): P[] => {
  const { findKey } = {
    findKey: 'id' as K | K[],
    ...(options || {})
  };
  const array = useCloneDeep<P[]>(arr);
  upsertElement(array, element, findElementIndex(array, element, findKey), options);
  return array;
};

const upsertMany = <P extends object, K extends keyof P>(
  arr: P[] = [],
  elements: P[],
  options?: UpsertOptions<P, K>
): P[] => {
  const { findKey } = {
    findKey: 'id' as K | K[],
    ...(options || {})
  };
  const array = useCloneDeep<P[]>(arr);

  elements.forEach(element => {
    upsertElement(array, element, findElementIndex(array, element, findKey), options);
  });

  return array;
};

const wrapInArray = <T = any>(v: T) => {
  return v === null || v === undefined ? [] : isArray(v) ? v : [v];
};

const updateItemArray = <P extends object, K extends keyof P>(
  arr: MaybeRef<P[]>,
  newItem: Partial<P>,
  prepareItem?: (item: P) => P,
  findItemKey = 'id' as K
): P[] =>
  useMap(unref(arr), item => {
    if (item?.[findItemKey] === newItem?.[findItemKey]) {
      const updateItem = mergeExcludeArray<P>(item, newItem);
      return prepareItem ? prepareItem(updateItem) : updateItem;
    }
    return item;
  });

const updateItemsArray = <P extends object, K extends keyof P>(
  arr: MaybeRef<P[]>,
  newItems: Partial<P>[],
  prepareItem?: (item: P) => P,
  findItemKey = 'id' as K
): P[] =>
  useMap(unref(arr), item => {
    if (item[findItemKey]) {
      const newItem = useFind(newItems, { [findItemKey]: item[findItemKey] });
      if (newItem) {
        const updateItem = mergeExcludeArray<P>(item, newItem as Partial<P>);
        return prepareItem ? prepareItem(updateItem) : updateItem;
      }
    }
    return item;
  });

const removeItemArray = <P extends object, K extends keyof P>(
  arr: MaybeRef<P[] | null | undefined>,
  excludeVal: string | number,
  key = 'id' as K
): P[] => useFilter(unref(arr), item => item?.[key] !== excludeVal);

const removeItemsArray = <P extends object, K extends keyof P>(
  arr: MaybeRef<P[] | null | undefined>,
  excludeVal: any[],
  key = 'id' as K
): P[] =>
  useFilter(
    unref(arr),
    item =>
      !excludeVal.some(val => {
        if (isObject(val)) {
          // @ts-expect-error
          return val[key] === item[key];
        }
        return val === item[key];
      })
  );

const arrayJoin = (source?: string | string[], separator?: string) =>
  isArray(source) ? useJoin(source, separator) : source;

export {
  upsert,
  upsertMany,
  arrayJoin,
  removeItemArray,
  updateItemArray,
  updateItemsArray,
  wrapInArray,
  mergeExcludeArray,
  removeItemsArray
};
