export default defineNuxtPlugin(async () => {
  const colorMode = useColorMode();
  const cookieThemeColor = useCookie(useRuntimeConfig().public.APP_NAME + '-them-color', { readonly: false });
  const { APP_LIGHT_COLOR, APP_DARK_COLOR } = useRuntimeConfig().public;
  useHead(
    computed(() => {
      const content = cookieThemeColor.value === 'dark' ? APP_DARK_COLOR : APP_LIGHT_COLOR;
      return {
        meta: [{ name: 'theme-color', content, hid: 'them-color' }]
      };
    })
  );

  if (IS_BROWSER) {
    watch(
      () => colorMode.value,
      val => {
        cookieThemeColor.value = val === 'dark' ? 'dark' : 'light';
      },
      { immediate: true }
    );
  }
});
