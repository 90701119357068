<template>
  <ui-container
    tag="section"
    spacing="none"
    no-gutters-x
  >
    <nuxt-layout
      name="settings"
      :error="error"
      :pending="pending"
    />
  </ui-container>
</template>

<script setup lang="ts">
  const apiRoutes = useApiRoutes();
  const user = useSanctumUser<User>();
  const { error, pending } = await useAsyncData<User>(async () => {
    const res = await apiRoutes.profile.show();
    const data = res._data.data;
    user.value = data;
    return data;
  });

  useHead({
    templateParams: {
      parentName: 'Settings'
    },
    title: 'App',
    titleTemplate: '%s %separator %parentName %separator %siteName'
  });
</script>
