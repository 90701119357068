import type { Channel } from 'pusher-js';
import Pusher from 'pusher-js';
import type { Ref } from 'vue';

const usePusherClient = (): Pusher | void => {
  return useNuxtApp().$pusherClient;
};

const handlePusherError =
  (cb: (...args: any[]) => void) =>
  (...args: any[]) => {
    try {
      // eslint-disable-next-line n/no-callback-literal
      cb(...args);
    } catch (e) {
      console.error(e);
    }
  };

const usePusherChannel = (channelName: MaybeRef<string | null>, channelKey: string, isPrivate?: boolean) => {
  const pusherClient = usePusherClient();
  const { isAuthenticated } = useSanctumAuth();
  const channel = useState<Channel | null>(channelKey, () => null);
  const chanelNamePrefix = isPrivate ? 'private-' : '';

  const createChannel = handlePusherError((channelRawName = channelName) => {
    const channelNameVal = unref(channelRawName);
    if (pusherClient && channelNameVal && channelNameVal !== channel.value?.name && isAuthenticated.value) {
      channel.value = pusherClient.subscribe(chanelNamePrefix + channelNameVal);
    }
  });

  const destroyChannel = handlePusherError((channelRawName = channelName) => {
    const channelNameVal = unref(channelRawName);
    if (pusherClient && channel) {
      pusherClient.unsubscribe(chanelNamePrefix + channelNameVal);
      channel.value = null;
    }
  });

  watch(
    () => unref(channelName),
    (newChannelName, oldChannelName) => {
      if (!newChannelName) {
        if (oldChannelName) {
          destroyChannel(oldChannelName);
        }
        return;
      }

      if (oldChannelName) {
        destroyChannel(oldChannelName);
      }

      createChannel(newChannelName);
    },
    {
      immediate: true
    }
  );

  tryOnScopeDispose(destroyChannel);

  return {
    channel,
    destroyChannel,
    createChannel
  };
};

type DefaultChanel = Ref<Channel | null>;

const usePusherEvent = (channel: DefaultChanel, eventName: string, bindCallback: (...args: any[]) => void) => {
  const addEvent = handlePusherError((activeChannelRaw: DefaultChanel = channel) => {
    const activeChannel = unref(activeChannelRaw);
    if (activeChannel && eventName) {
      activeChannel.bind(eventName, bindCallback);
    }
  });

  const removeEvent = handlePusherError((activeChannelRaw: DefaultChanel = channel) => {
    const activeChannel = unref(activeChannelRaw);
    if (activeChannel && eventName) {
      activeChannel.unbind(eventName, bindCallback);
    }
  });

  watch(
    channel,
    (newChannel, oldChannel) => {
      if (newChannel && oldChannel) {
        removeEvent(oldChannel);
        addEvent(newChannel);
        return;
      }
      if (newChannel && !oldChannel) {
        addEvent(newChannel);
        return;
      }
      removeEvent(oldChannel);
    },
    {
      immediate: true
    }
  );
  tryOnScopeDispose(removeEvent);
};

export { usePusherChannel, usePusherEvent };
